import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

import LandingCattenna from './pages/landingCattenna.jsx';
import LandingClient from './pages/landingClient.jsx';
import Login from "./pages/login.jsx";
import Home from "./pages/home.jsx";
import Dashboard from "./components/dashboard/dashboard.jsx";
import Suppliers from "./components/suppliers/suppliers.jsx";
import SupplierProfile from "./components/suppliers/suppliersProfile.jsx";
import Industries from "./components/industries/industries.jsx";
import Requests from "./components/requests/requests.jsx";
import ReviewForAuth from "./components/requests/reviewForAuth.jsx";
import ComplianceSetup from "./components/complianceSetup/complianceSetup.jsx";
import MyProfile from "./components/myProfile/myProfile.jsx";
import NotFoundUrl from "./pages/404.jsx";
import IndustryConfiguration from "./components/industries/industryConfiguration.jsx";
import Maintenance from "./pages/maintenance.jsx";
import ClientsAdminView from "./components/clientsAdminView/clientsAdminView.jsx";
import RegisterClient from "./components/clientsAdminView/registerClient.jsx";
import EditClient from "./components/clientsAdminView/editClient.jsx";
import ClientAlerts from "./components/clientAlerts/clientAlerts.jsx";
import ClientsSupplierView from "./components/clientsSupplierView/clientsSupplierView.jsx";
import ClientInformation from "./components/clientsSupplierView/clientInformation.jsx";
import SupplierAlerts from "./components/supplierAlerts/supplierAlerts.jsx";
import SecurityStaff from './components/securityStaff/securityStaff.jsx';

const URI_MAINTENANCE = process.env.REACT_APP_URI_MAINTENANCE_MODE

export default function App() {
	const [cookie] = useCookies(["userLogged"])

	const [maintenance, setMaintenance] = useState(null)
	const [darkLightMode, setDarkLightMode] = useState(cookie?.userLogged?.darkLightMode) //false = light

	const mainStyle = {
		background: darkLightMode ? "#202B34" : "#F5F6F7",
		color: darkLightMode ? "white" : "inherit"
	}
	
	useEffect(() => {
		getMaintenance()
	}, [])

	function updateModal() {
		const root = document.getElementById('root');
		const someChild = document.querySelector('.modal .modal-content');
		
		if (!someChild) return

        if (someChild && root.querySelector('.darkMode')) {
             // Define a function to apply styles
			const applyStyle = (elements, styles) => {
                elements.forEach(ele => {
                    Object.entries(styles).forEach(([key, value]) => {
                        ele.style[key] = value;
                    });
                });
            };

            // Apply styles to someChild
            applyStyle([someChild], {
                backgroundColor: '#131E29',
                webkitTextFillColor: '#FFFFFF'
            });

            // Define common styles for inputs and headers
            const commonInputStyle = {
                background: '#27343F'
            };

            // Apply styles to table header, inputs, and dropdowns
            applyStyle(document.querySelectorAll('.modal .modal-content th, .modal .modal-content .inputContainer > input, .modal .modal-content .inputContainer > textarea, .modal .modal-content .inputContainer .dropdown-toggle, .modal .modal-content .infoContainer > input'), commonInputStyle);

            // Apply styles to dropdown items
            applyStyle(document.querySelectorAll('.modal .modal-content .dropdown-item'), { backgroundColor: commonInputStyle.background });

            applyStyle(document.querySelectorAll('.product-modal .products-list, .product-modal .searchContainer > input'), { backgroundColor: "#27343F" });

            applyStyle(document.querySelectorAll('.product-modal .product-container > input'), { border: "1px solid #FFFFFF" });

            // Apply styles to actions
            applyStyle(document.querySelectorAll('.modal .modal-content .actions > div'), {
                backgroundColor: '#FFFFFF'
            });

            // Apply styles to action dropdowns
            // applyStyle(document.querySelectorAll('.modal .modal-content .actions .dropdown .dropdown-toggle, .modal .modal-content .actions .dropdown .newAlone, .modal .modal-content .actions .dropdown .successAlone, .modal .modal-content .actions .dropdown .pendingAlone'), commonInputStyle);
            applyStyle(document.querySelectorAll('.modal .modal-content .actions .dropdown .dropdown-toggle, .modal .modal-content .actions .dropdown .dropdown-item, .warehouses-modal-inputs input'), commonInputStyle);

            // Define text fill colors for action dropdowns
            const dropdownColors = {
                '.newAlone': '#00A3FF',
                '.successAlone': '#08D1A1',
                '.pendingAlone': '#FF7B31'
            };

            Object.entries(dropdownColors).forEach(([selector, color]) => {
                applyStyle(document.querySelectorAll(`.modal .modal-content .actions .dropdown ${selector}`), {
                    webkitTextFillColor: color,
					backgroundColor: commonInputStyle.background
                });
            });
        }
	}
	
	useEffect(() => {
        document.body.addEventListener("click", updateModal)

        return function cleanUp() {
            document.body.removeEventListener("click", updateModal)
        }
    }, [])
	
	useEffect(() => {
		if (cookie.userLogged) {
			setDarkLightMode(cookie.userLogged?.darkLightMode)
		}
	}, [cookie.userLogged])

	const getMaintenance = () => {
		axios.get(URI_MAINTENANCE).then(response => {
			setMaintenance(response.data)
		}).catch(err => {
			console.log("Error al obtener el modo mantenimiento: " + err)
		})
	}

	return (
		<React.Fragment>
			<BrowserRouter>
				{maintenance && <Routes>
					<Route path='/' element={maintenance.maintenanceMode ? <Maintenance /> : <LandingCattenna />} />
					<Route path={maintenance.maintenanceMode ? '/maintenance/:clientUrl' : '/:clientUrl'} element={<LandingClient maintenanceMode={maintenance.maintenanceMode} />} />
					<Route path={maintenance.maintenanceMode ? '/maintenance/login' : '/login'} element={<Login />} />
					<Route path={maintenance.maintenanceMode ? '/maintenance/home' : '/home'} element={<Home maintenanceMode={maintenance.maintenanceMode} mainStyle={mainStyle} darkLightMode={darkLightMode} setDarkLightMode={setDarkLightMode} />}>
						<Route path='complianceSetup' element={<ComplianceSetup darkLightMode={darkLightMode} />} />
						<Route path='myProfile' element={<MyProfile darkLightMode={darkLightMode} />} />
						<Route path='dashboard' element={<Dashboard darkLightMode={darkLightMode} />} />
						<Route path='suppliers' element={<Suppliers darkLightMode={darkLightMode} />} />
						<Route path='suppliers/supplierProfile/:id' element={<SupplierProfile darkLightMode={darkLightMode} />} />
						<Route path='industries' element={<Industries darkLightMode={darkLightMode} />} />
						<Route path='industries/:id' element={<IndustryConfiguration darkLightMode={darkLightMode} />} />
						<Route path='requests' element={<Requests darkLightMode={darkLightMode} />} />
						<Route path='requests/reviewForAuth/:id' element={<ReviewForAuth darkLightMode={darkLightMode} />} />
						<Route path='clients' element={<ClientsAdminView darkLightMode={darkLightMode} />} />
						<Route path='clients/registerClient' element={<RegisterClient darkLightMode={darkLightMode} />} />
						<Route path='clients/clientProfile/:id' element={<EditClient darkLightMode={darkLightMode} />} />
						<Route path='alerts' element={<ClientAlerts darkLightMode={darkLightMode} />} />
						<Route path='myClients' element={<ClientsSupplierView />} />
						<Route path='myClients/clientInformation/:id' element={<ClientInformation />} />
						<Route path='myAlerts' element={<SupplierAlerts />} />
						<Route path='securityStaff' element={<SecurityStaff darkLightMode={darkLightMode} />} />
					</Route>
					<Route path='/404' element={<NotFoundUrl />} />
					<Route path='*' element={maintenance.maintenanceMode ? <Maintenance /> : <Navigate to={"/404"} />} />
				</Routes>}
			</BrowserRouter>
			<TawkMessengerReact
				propertyId={process.env.REACT_APP_PROPERTYID}
				widgetId={process.env.REACT_APP_WIDGETID} />
		</React.Fragment>
	)
}