import Swal from "sweetalert2";
import close from "../../../assets/images/close.svg"

import { Modal } from "react-bootstrap";

export default function AddStaff(props) {
	// VARIABLES RECIEVED FROM PROPS
	const { showAddModal, onHide, handleAddMemberDocument } = props

	// FUNCTION TO HANDLE THE DOCUMENT SELECTION
	const handleDocumentSelection = (target, typeDocument) => {
		if (target.files[0].type === "application/pdf") {
			handleAddMemberDocument(target.files[0], typeDocument)
			onHide()
		} else {
			target.value = null
			Swal.fire({
				icon: 'error',
				text: 'The file is not a PDF format.',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		}
	}

	return (
		<Modal
			className="addStaffModal"
			show={showAddModal}
			onHide={onHide}
			size="lg"
			centered>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => onHide()} alt="Prodensa Supplier" />

				<h4 className="title">Add members</h4>
				<p className="description">
					Please select a file to add a new member
				</p>
				<div className="inputSelection">
					<div className="inputContainer">
						<span className="label">SUA</span>
						<input type="file" onChange={(e) => handleDocumentSelection(e.target, "SUA")} />
					</div>

					<h5 className="separator">OR</h5>

					<div className="inputContainer">
						<span className="label">Acuse de alta de empleado</span>
						<input type="file" onChange={(e) => handleDocumentSelection(e.target, "Acuse")} />
					</div>
				</div>
				<button className="btnBgWhiteTextBlack" onClick={() => onHide()}>Cancel</button>
			</Modal.Body>
		</Modal>
	)
}