import "../assets/css/pages/404.css"
import logo from "../assets/images/logo.png"
import wordl404 from "../assets/images/world404.svg"
import notFound404 from "../assets/images/404.svg"
import { Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export default function NotFoundUrl() {
	const navigate = useNavigate()

	return (
		<section className="notFound">
			<div className="container">
				<Row>
					<Col lg={12} xs={{ offset: 1, span: 10 }}>
						<img className="logo" src={logo} alt="Prodensa Supplier" />
						<img className="world" src={wordl404} alt="Prodensa Supplier" />
						<img className="img404" src={notFound404} alt="Prodensa Supplier" />
						<div className="titleAndAction">
							<h4>Oops.. the page you are trying to access is not available. If you're a supplier, please log in through your client's supplier portal.</h4>
							<button className="btnBgBlueTextGradient" onClick={() => navigate(`/`)}>Login</button>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	)
}