import Swal from "sweetalert2";
import axios from "axios";
const VERIFY_SUA_STATUS_URL = process.env.REACT_APP_URI_VERIFY_SUA_STATUS;

const monitorJobStatus = (jobId, docCompliance) => {
    console.log("Entra al monitorJobStatus con el jobId", jobId);
    return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            try {
                const response = await axios.get(`${VERIFY_SUA_STATUS_URL}${jobId}`);
                const status = response?.data?.status;
                const result = response?.data?.result;

                console.log(result);

                if (status === "completed") {
                    console.log("Status job: Completed");
                    clearInterval(interval);
                    Swal.fire({
                        icon: "success",
                        text: "Process completed successfully!",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    }).then(() => {
                        if (!docCompliance) {
                            window.location.reload();
                        }
                        resolve({
                            status: "Ok",
                            result
                        });
                    });
                } else if (status === "failed") {
                    console.log("Status job: Failed");
                    clearInterval(interval);
                    Swal.fire({
                        icon: "error",
                        text: "Process failed: " + result,
                        showConfirmButton: true,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                    resolve({
                        status: "Failed",
                        result
                    });
                }
            } catch (error) {
                console.log("Status job: ERROR");
                clearInterval(interval);
                Swal.fire({
                    icon: "error",
                    text: "Failed to retrieve job status.",
                    showConfirmButton: true,
                    timer: 5000,
                    timerProgressBar: true,
                });
                console.log("Error al consultar el estado del trabajo: " + error);
                resolve({
                    status: "Failed",
                    result: "Error validating the document."
                });
            }
        }, 5000);
    });
};

export default monitorJobStatus;
