/* FORMAT COMMAS */
export const formatCommas = (value) => {
    const rawValue = value.toString();
    const numericValue = rawValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
    const formattedValue = new Intl.NumberFormat('en-US').format(Number(numericValue));
    /*
        Return values
        formattedValue: Number for display with commas
        numericValue: Raw value for storage
    */
    return {
        formattedValue, numericValue
    }
}