import { useEffect, useState } from "react"
import "../../assets/css/tabs/submission.css"

export default function Submission(props) {
	const [informationCompliancePercent, setInformationCompliancePercent] = useState(0)
	const [documentCompliancePercent, setDocumentCompliancePercent] = useState(0)

	useEffect(() => {
		let contadorInformationCompliance = 0
		let contadorTotalInformationCompliance = 0

		Object.values(props.supplier.informationCompliance.generalInformation).forEach(value => {
			return (
				value !== "" ? contadorInformationCompliance++ : undefined,
				contadorTotalInformationCompliance++
			)
		})

		Object.values(props.supplier.informationCompliance.facilitiesCapabilities).forEach(value => {
			return (
				value !== "" ? contadorInformationCompliance++ : undefined,
				contadorTotalInformationCompliance++
			)
		})

		Object.values(props.supplier.informationCompliance.salesInformation).forEach(value => {
			return (
				value !== "" ? contadorInformationCompliance++ : undefined,
				contadorTotalInformationCompliance++
			)
		})

		setInformationCompliancePercent(Math.round(contadorInformationCompliance / contadorTotalInformationCompliance * 100))

		let contadorDocumentCompliance = 0
		let contadorTotalDocumentCompliance = 0

		let documentsForCheck = []
		props.supplier.documentCompliance.genericDocuments.general.length > 0 && props.supplier.documentCompliance.genericDocuments.general.forEach(document => {
			documentsForCheck.push(document)
		})

		props.supplier.documentCompliance.genericDocuments.periodic.length > 0 && props.supplier.documentCompliance.genericDocuments.periodic.forEach(document => {
			documentsForCheck.push(document)
		})

		props.supplier.documentCompliance.clients.length > 0 && props.supplier.documentCompliance.clients.forEach(clients => {
			clients.clientDocuments.general.length > 0 && clients.clientDocuments.general.forEach(document => {
				documentsForCheck.push(document)
			})

			clients.clientDocuments.periodic.length > 0 && clients.clientDocuments.periodic.forEach(document => {
				documentsForCheck.push(document)
			})
		})

		Object.values(documentsForCheck).forEach(value => {
			return (
				value.document ? contadorDocumentCompliance++ : undefined,
				contadorTotalDocumentCompliance++
			)
		})

		const docPercentage = contadorTotalDocumentCompliance ? Math.round(contadorDocumentCompliance / contadorTotalDocumentCompliance * 100) : 0
		
		setDocumentCompliancePercent(docPercentage)
	}, [props])

	return (
		<section className="submission">
			<h5 className="titleDocuments">Progress</h5>
			<table className="tableSubmission">
				<thead>
					<tr>
						<th><span>Name</span></th>
						<th></th>
						<th><span>% Completed</span></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div className="infoContainer">
								<span>Information compliance</span>
							</div>
						</td>
						<td>
							<div className="infoContainer justify-content-end">
								<span>Information progress</span>
							</div>
						</td>
						<td>
							<div className="infoContainer">
								<div className="percentContainer">
									<div className="bar">
										<div className="barFill" style={{ width: (informationCompliancePercent ? informationCompliancePercent : 0) + "%" }}></div>
									</div>
									<span className="percent">{(informationCompliancePercent ? informationCompliancePercent : 0)}%</span>
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div className="infoContainer">
								<span>Document compliance</span>
							</div>
						</td>
						<td>
							<div className="infoContainer justify-content-end">
								<span>Document upload</span>
							</div>
						</td>
						<td>
							<div className="infoContainer">
								<div className="percentContainer">
									<div className="bar">
										<div className="barFill" style={{ width: (documentCompliancePercent ? documentCompliancePercent : 0) + "%" }}></div>
									</div>
									<span className="percent">{(documentCompliancePercent ? documentCompliancePercent : 0)}%</span>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<div className="submissionProgress d-lg-none">
				<div className="progressInformation">
					<span className="title">Information compliance</span>
					<span className="subTitle">Information progress</span>
					<div className="percentContainer">
						<div className="bar">
							<div className="barFill" style={{ width: (informationCompliancePercent ? informationCompliancePercent : 0) + "%" }}></div>
						</div>
						<span className="percent">{(informationCompliancePercent ? informationCompliancePercent : 0)}%</span>
					</div>
				</div>
				<div className="progressInformation">
					<span className="title">Document compliance</span>
					<span className="subTitle">Document upload</span>
					<div className="percentContainer">
						<div className="bar">
							<div className="barFill" style={{ width: (documentCompliancePercent ? documentCompliancePercent : 0) + "%" }}></div>
						</div>
						<span className="percent">{(documentCompliancePercent ? documentCompliancePercent : 0)}%</span>
					</div>
				</div>
			</div>
		</section>
	)
}