import "../../assets/css/clientsAdminView/editClient.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import goBack from "../../assets/images/arrowForward.svg"
import deleteIcon from "../../assets/images/delete.svg"
import moreDots from "../../assets/images/moreDots.svg"

import AddEntityModal from "./addEntity.jsx"
import EditEntityModal from "./editEntity.jsx"
import UsersControlModal from "./userControl.jsx"

import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import { useCookies } from "react-cookie"
import { Dropdown } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { checkTheEmailStruct } from "../../utilities/validateEmail.js"
import Swal from "sweetalert2"
import MassiveUploadModal from "./massiveUploadModal.jsx"

const URI_CLIENT = process.env.REACT_APP_URI_CLIENT
const URI_CLIENT_BY_URL = process.env.REACT_APP_URI_CLIENT_BY_URL
const URI_INDUSTRY = process.env.REACT_APP_URI_INDUSTRY
const URI_DISABLE_CLIENT = process.env.REACT_APP_URI_DISABLE_CLIENT

export default function EditClient({ darkLightMode }) {
	// VARIABLES TO MANAGE THE USER LOGGED
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged

	// VARIABLE TO CONTROL THE CLIENT INFORMATION
	const [client, setClient] = useState(null)

	// VARIABLE TO MANAGE THE ADD ENTITIES FUNCTIONALITY
	const [entities, setEntities] = useState(null)

	// CATEGORIES INFORMATION
	const [industrySupplier, setIndustrySupplier] = useState(null)

	// VARIABLES TO MANAGE THE ADD ENTITY MODAL
	const [addModalShow, setAddModalShow] = useState(false)

	// VARIABLES TO MANAGE THE EDIT ENTITY MODAL
	const [editModalShow, setEditModalShow] = useState(false)
	const [entityToEdit, setEntityToEdit] = useState(null)
	const [entityPosition, setEntityPosition] = useState(null)

	// VARIABLE TO MANAGE THE USER MODAL
	const [usersControlModal, setUsersControlModal] = useState(false)

	// VARIABLE TO MANAGE THE MASSIVE UPLOAD MODAL
	const [showMassiveUploadModal, setShowMassiveUploadModal] = useState(false)

	// VARIABLE TO MANAGE THE REF
	const moreDotsRef = useRef(null)

	// VARIABLE TO NAVIGATE
	const navigate = useNavigate();

	// VARIABLE TO GET THE CLIENT ID
	const { id } = useParams()

	useEffect(() => {
		getClientById(id)
		getAllSupplierIndustries()

		document.addEventListener("click", closeMenus)

		return function cleanUp() {
			document.removeEventListener("click", closeMenus)
		}
	}, [])

	useEffect(() => {
		if (entities) {
			setClient(prevState => {
				return {
					...prevState,
					entities: entities
				}
			})
		}
	}, [entities])

	// FUNTION TO HANDLE THE MENU CLOSE
	const closeMenus = (e) => {
		let menuOptions;

		if (moreDotsRef.current && moreDotsRef.current.contains(e.target)) {
			menuOptions = Array.prototype.slice.call(document.getElementsByClassName("menuOptions"))
			menuOptions.map(element => {
				if (!moreDotsRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			})

			moreDotsRef.current.nextElementSibling.classList.toggle("show")
		} else {
			menuOptions = Array.prototype.slice.call(document.getElementsByClassName("menuOptions"))
			menuOptions.map(element => {
				element.classList.remove("show")
			})
		}
	}

	// FUNCTION TO ADD OR REMOVE CATEGORIES TO THE CLIENT
	const addCategory = (target) => {
		const industriesCopy = JSON.parse(JSON.stringify(client.generalInformation.clientIndustries))
		if (industriesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			industriesCopy.splice(industriesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			industriesCopy.push(target.value.toUpperCase())
		}

		setClient(prevState => {
			return {
				...prevState,
				generalInformation: {
					...prevState.generalInformation,
					clientIndustries: industriesCopy,
				}
			}
		})
	}

	// FUNCTION TO ADD OR REMOVE PURCHASED MODULES TO THE CLIENT
	const addPurchasedModules = (target) => {
		const purchasedModulesCopy = JSON.parse(JSON.stringify(client.generalInformation.clientActiveModules))
		if (purchasedModulesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			purchasedModulesCopy.splice(purchasedModulesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			purchasedModulesCopy.push(target.value.toUpperCase())
		}

		setClient(prevState => {
			return {
				...prevState,
				generalInformation: {
					...prevState.generalInformation,
					clientActiveModules: purchasedModulesCopy,
				}
			}
		})
	}

	// FUNCTION TO ADD OR REMOVE PAYMENT WAY TO THE CLIENT
	const addPaymentWay = (target) => {
		const paymentWayCopy = JSON.parse(JSON.stringify(client.billingInformation.clientPaymentWay))
		if (paymentWayCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			paymentWayCopy.splice(paymentWayCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			paymentWayCopy.push(target.value.toUpperCase())
		}

		setClient(prevState => {
			return {
				...prevState,
				billingInformation: {
					...prevState.billingInformation,
					clientPaymentWay: paymentWayCopy,
				}
			}
		})
	}

	// FUNCTION TO ADD OR REMOVE PAYMENT WAY TO THE CLIENT
	const addCurrency = (target) => {
		const currenciesCopy = JSON.parse(JSON.stringify(client.billingInformation.clientCurrencies))
		if (currenciesCopy.some(valueToFind => valueToFind === target.value.toUpperCase())) {
			currenciesCopy.splice(currenciesCopy.findIndex(valueToFind => valueToFind === target.value.toUpperCase()), 1)
		} else {
			currenciesCopy.push(target.value.toUpperCase())
		}

		setClient(prevState => {
			return {
				...prevState,
				billingInformation: {
					...prevState.billingInformation,
					clientCurrencies: currenciesCopy,
				}
			}
		})
	}

	// FUNCTION TO GET ALL THE SUPPLIER INDUSTRIES
	const getAllSupplierIndustries = () => {
		axios.get(URI_INDUSTRY).then(response => {
			setIndustrySupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener industrias del servidor" + err)
		})
	}

	// FUNCTION TO GET THE CLIENT BY ID
	const getClientById = (id) => {
		axios.post(URI_CLIENT + id, {
			rol: userLogged.rol
		}).then(response => {
			setClient(response.data)
			setEntities(response.data.entities)
		}).catch(err => {
			console.log("Error al obtener el cliente: " + err)
		})
	}

	// FUNCTION TO HANDLE DE CLIENT FIELDS CHANGE
	const handleClientChange = (parentProperty, property, value) => {
		const clientCopy = JSON.parse(JSON.stringify(client))

		clientCopy[parentProperty][property] = value
		if (property === "clientCountry") {
			clientCopy[parentProperty].clientTypePerson = ""
			clientCopy[parentProperty].clientRfc = ""
			clientCopy[parentProperty].clientState = ""
		}

		setClient(clientCopy)
	}

	// FUNCTION TO HANDLE THE CONTACT INFORMATION CHANGE
	const handleContactInformationChange = (position, property, value) => {
		const contactsCopy = JSON.parse(JSON.stringify(client.contacts))

		contactsCopy[position][property] = value

		setClient(prevState => {
			return {
				...prevState,
				contacts: contactsCopy
			}
		})
	}

	// FUNCTION TO ADD CONTACTS
	const handleAddContact = () => {
		const contactsCopy = JSON.parse(JSON.stringify(client.contacts))

		contactsCopy.push({
			name: "",
			email: "",
			phone: ""
		})

		setClient(prevState => {
			return {
				...prevState,
				contacts: contactsCopy
			}
		})
	}

	// FUNCTION TO DELETE CONTACTS
	const handleDeleteContact = (position) => {
		const contactsCopy = JSON.parse(JSON.stringify(client.contacts))

		contactsCopy.splice(position, 1)

		setClient(prevState => {
			return {
				...prevState,
				contacts: contactsCopy
			}
		})
	}

	// FUNCTION TO DELETE ENTITIES
	const handleDeleteEntities = (position) => {
		const entitiesCopy = JSON.parse(JSON.stringify(client.entities))

		entitiesCopy.splice(position, 1)

		setClient(prevState => {
			return {
				...prevState,
				entities: entitiesCopy
			}
		})
	}

	// FUNTION TO SHOW THE EDIT MODAL
	const handleShowEditModal = (position, entity) => {
		setEditModalShow(true)
		setEntityPosition(position)
		setEntityToEdit(entity)
	}

	// FUNTION TO EDIT THE ENTITY
	const handleEditEntity = (entity) => {
		const entitiesCopy = JSON.parse(JSON.stringify(client.entities))

		entitiesCopy[entityPosition] = entity

		setClient(prevState => {
			return {
				...prevState,
				entities: entitiesCopy
			}
		})
	}

	// FUNCTION TO HANDLE THE EDIT CLIENT
	const handleEditClient = () => {
		if (client.entities.some(entity => !entity.entityUrlName)) {
			Swal.fire({
				icon: 'error',
				text: 'Please fill out the urlName field from the entities',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		} else {
			Swal.fire({
				footer: 'Loading, please wait',
				showConfirmButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				didOpen: () => {
					Swal.showLoading()
				},
			})

			axios.put(URI_CLIENT + id, {
				client: client
			}).then(response => {
				Swal.fire({
					icon: 'success',
					text: 'Client updated successfully',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			}).catch(err => {
				Swal.fire({
					icon: 'error',
					text: 'Failed to update the client',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
				console.log("Error al actualizar la informacion del cliente: " + err)
			})
		}
	}

	// FUNCTION TO CHANGE THE ACTIVE STATUS FOR THE CLIENT
	const handleChangeActiveClient = () => {
		const clientCopy = JSON.parse(JSON.stringify(client))
		clientCopy.generalInformation.clientActive = !clientCopy.generalInformation.clientActive

		const idsToDisable = {
			newStatus: clientCopy.generalInformation.clientActive,
			clientID: client._id,
			entitiesIds: client.entities.map(entity => entity._id)
		}

		axios.post(URI_DISABLE_CLIENT, {
			idsToDisable: idsToDisable
		}).then(response => {
			Swal.fire({
				icon: 'success',
				text: 'Client updated successfully',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			setClient(clientCopy)
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				text: 'Failed to update the client',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			console.log("Error al actualizar la informacion del cliente: " + err)
		})
	}

	// FUNCTION TO HANDLE THE DELETE CLIENT
	const handleDeleteClient = () => {
		Swal.fire({
			title: "Are you sure you want to delete this client?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Yes, delete",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					footer: 'Loading, please wait',
					showConfirmButton: false,
					allowOutsideClick: false,
					allowEscapeKey: false,
					didOpen: () => {
						Swal.showLoading()
					},
				})

				axios.delete(URI_CLIENT + id).then(response => {
					Swal.fire({
						icon: 'success',
						text: 'Client deleted successfully',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					navigate(-1)
				}).catch(err => {
					Swal.fire({
						icon: 'error',
						text: 'Failed to delete the client',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					console.log("Error al actualizar la informacion del cliente: " + err)
				})
			}
		});
	}

	// FUNCTION TO CHECK IF THE URL NAME ALREADY EXIST
	const checkEntitiesUrlName = async (urlNameToCheck) => {
		const entitiesCopy = JSON.parse(JSON.stringify(entities))

		const localEntityUrlFound = entitiesCopy.some(entity => entity.entityUrlName === urlNameToCheck)

		const databaseClientFound = await axios.post(URI_CLIENT_BY_URL, { clientUrl: urlNameToCheck })

		return localEntityUrlFound || databaseClientFound.data ? true : false
	}

	return (
		<section className={`editClient ${darkLightMode ? "darkMode" : null}`}>
			<div className="titleAndBack">
				<img className="goBack" src={goBack} onClick={() => navigate(-1)} alt="Cattenna Platform" />
				<h3>{client?.generalInformation.clientName}</h3>
			</div>
			<div className="clientQuickActions">
				<button className="btnBgBlueTextGradient" onClick={() => navigate(`/home/suppliers/?viewClient=${client._id}`)}>View suppliers</button>
				<button className="btnBgBlueTextGradient" onClick={handleChangeActiveClient}>{client?.generalInformation.clientActive ? "Disable client" : "Active client"}</button>
				<button className="btnBgBlueTextGradient" onClick={handleDeleteClient}>Delete client</button>
				<button className="btnBgBlueTextGradient" onClick={() => setUsersControlModal(true)}>Client credentials</button>
				<button className="btnBgBlueTextGradient" onClick={() => setShowMassiveUploadModal(true)}>Massive upload</button>
			</div>
			<h5 className="titleHeader">Client Information</h5>
			<div className="inputSections">
				<div className="inputContainer">
					<span className="label">Name</span>
					<input type="text" placeholder="Client name" value={client?.generalInformation.clientName}
						onChange={(e) => handleClientChange("generalInformation", "clientName", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Industry</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.generalInformation.clientIndustries.length > 0 ? "" : "default"}>
							{client?.generalInformation.clientIndustries.length > 0 ? client?.generalInformation.clientIndustries.map(category => category + ", ") : "Industry"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{client && industrySupplier && industrySupplier.map((industry, index) => {
								if (industry.name !== "Global") {
									return (
										<div key={index} className="optionCategory">
											<input id={`categories${index}`} type="checkbox" defaultChecked={client.generalInformation.clientIndustries.some(category => category === industry.name.toUpperCase()) ? true : false} value={industry.name} onClick={(e) => addCategory(e.target)} />
											<label htmlFor={`categories${index}`}>{industry.name}</label>
										</div>
									)
								}
							})}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Active Modules</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.generalInformation.clientActiveModules.length > 0 ? "" : "default"}>
							{client?.generalInformation.clientActiveModules.length > 0 ? client?.generalInformation.clientActiveModules.map(module => module + ", ") : "Active Modules"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id="purchasing" type="checkbox" value="Purchasing" onClick={(e) => addPurchasedModules(e.target)}
									defaultChecked={client?.generalInformation.clientActiveModules.some(module => module === "PURCHASING")}
								/>
								<label htmlFor="purchasing">Purchasing</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer calendar">
					<span className="label">Active since</span>
					<input value={client?.generalInformation.clientActiveSince} type="date"
						onChange={(e) => handleClientChange("generalInformation", "clientActiveSince", e.target.value)}
					/>
				</div>
				<div className="inputContainer calendar">
					<span className="label">Renovation Date</span>
					<input value={client?.generalInformation.clientRenovationDate} type="date"
						onChange={(e) => handleClientChange("generalInformation", "clientRenovationDate", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Commercial Name</span>
					<input type="text" placeholder="Commercial Name" value={client?.generalInformation.clientCommercialName}
						onChange={(e) => handleClientChange("generalInformation", "clientCommercialName", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Signed Contract</span>
					<input type="text" placeholder="Client PandaDoc URL" value={client?.generalInformation.clientSignedContract}
						onChange={(e) => handleClientChange("generalInformation", "clientSignedContract", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Notification contacts <bg-grey>(Separate by comma)</bg-grey></span>
					<input value={client?.generalInformation.clientNotificationContacts} type="text" placeholder="example1@example.com, example2@example.com"
						onChange={(e) => handleClientChange("generalInformation", "clientNotificationContacts", e.target.value)} />
				</div>

				<h5 className="sectionTitle">Billing information</h5>
				<div className="inputContainer">
					<span className="label">Company name</span>
					<input type="text" placeholder="Company name" value={client?.billingInformation.clientCompanyName}
						onChange={(e) => handleClientChange("billingInformation", "clientCompanyName", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Tax regime</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientTaxRegime ? "" : "default"}>
							{client?.billingInformation.clientTaxRegime ? client?.billingInformation.clientTaxRegime : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>601 - General de Ley Personas Morales</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>603 - Personas Morales con Fines no Lucrativos</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>610 - Residentes en el Extranjero sin Establecimientos Permanente en México</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>623 - Opcional para Grupos de Sociedades</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>624 - Coordinados</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientTaxRegime", e.target.innerText)}>626 - Régimen Simplificado de Confianza</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Country</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientCountry ? "" : "default"}>
							{client?.billingInformation.clientCountry ? client?.billingInformation.clientCountry : "Select country"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{countryInformation.map((information, index) => (
								<Dropdown.Item key={index} onClick={(e) => handleClientChange("billingInformation", "clientCountry", e.target.innerText)}>{information.country}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">
						{client && countryInformation.find(countries => {
							return countries.country.toUpperCase() === client.billingInformation.clientCountry.toUpperCase()
						})?.typePersonLabel}
					</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientTypePerson ? "" : "default"}>
							{client?.billingInformation.clientTypePerson ? client?.billingInformation.clientTypePerson : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{client && countryInformation.find(countries => countries.country.toUpperCase() === client.billingInformation.clientCountry.toUpperCase())?.typePersonValues.map((person, index) => (
								<Dropdown.Item key={index} onClick={(e) => handleClientChange("billingInformation", "clientTypePerson", e.target.innerText)}>{person}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className={`inputContainer`}>
					<span className="label">
						{client && countryInformation.find(countries => {
							return countries.country.toUpperCase() === client.billingInformation.clientCountry.toUpperCase()
						})?.rfcLabel}
					</span>
					<input id="inputRfc" value={client?.billingInformation.clientRfc}
						onChange={(e) => {
							if (client?.billingInformation.clientCountry.toLowerCase() === "méxico")
								handleClientChange("billingInformation", "clientRfc", e.target.value)
							else if (client?.billingInformation.clientCountry.toLowerCase() === "united states of america") {
								const value = e.target.value.replace(/[^\d]/g, "")
								handleClientChange("billingInformation", "clientRfc", value)
							}
						}}
						type="text"
						placeholder={client && countryInformation.find(countries => {
							return countries.country.toUpperCase() === client.billingInformation.clientCountry.toUpperCase()
						})?.rfcPlaceholder} />
				</div>
				<div className="inputContainer">
					<span className="label">Hold Payment email</span>
					<input value={client?.billingInformation.clientBillingEmail}
						onChange={(e) => handleClientChange("billingInformation", "clientBillingEmail", e.target.value)}
						type="email" placeholder="Email address"
						onBlur={() => handleClientChange("billingInformation", "clientBillingEmail", checkTheEmailStruct(client?.billingInformation.clientBillingEmail))}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">State</span>
					<Dropdown>
						<Dropdown.Toggle className={`${client?.billingInformation.clientState ? "" : "default"}`}>
							{client?.billingInformation.clientState ? client?.billingInformation.clientState : "State"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{client && countryInformation.find(countries => countries.country.toUpperCase() === client.billingInformation.clientCountry.toUpperCase())?.states.map((state, index) => (
								<Dropdown.Item key={index} onClick={(e) => handleClientChange("billingInformation", "clientState", e.target.innerText)}>{state.name}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">C.P:</span>
					<input type="text" placeholder="Postal code" value={client?.billingInformation.clientPostalCode}
						onChange={(e) => handleClientChange("billingInformation", "clientPostalCode", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Street, interior & exterior number</span>
					<input type="text" placeholder="Street, interior & exterior number" value={client?.billingInformation.clientStreetAndNumber}
						onChange={(e) => handleClientChange("billingInformation", "clientStreetAndNumber", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Neighborhood</span>
					<input type="text" placeholder="Neighborhood" value={client?.billingInformation.clientNeighborhood}
						onChange={(e) => handleClientChange("billingInformation", "clientNeighborhood", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Municipality</span>
					<input type="text" placeholder="Municipality" value={client?.billingInformation.clientMunicipality}
						onChange={(e) => handleClientChange("billingInformation", "clientMunicipality", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Payment method</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientPaymentMethod ? "" : "default"}>
							{client?.billingInformation.clientPaymentMethod ? client?.billingInformation.clientPaymentMethod : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientPaymentMethod", e.target.innerText)}>Pago PUE</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientPaymentMethod", e.target.innerText)}>Pago PPD</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Payment way</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientPaymentWay.length > 0 ? "" : "default"}>
							{client?.billingInformation.clientPaymentWay.length > 0 ? client?.billingInformation.clientPaymentWay.map(payment => payment + ", ") : "Payment way"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id="cash" type="checkbox" value="Cash" onClick={(e) => addPaymentWay(e.target)}
									defaultChecked={client?.billingInformation.clientPaymentWay.some(payment => payment === "CASH")}
								/>
								<label htmlFor="cash">Cash</label>
							</div>
							<div className="optionCategory">
								<input id="wireTransfer" type="checkbox" value="Wire Transfer" onClick={(e) => addPaymentWay(e.target)}
									defaultChecked={client?.billingInformation.clientPaymentWay.some(payment => payment === "WIRE TRANSFER")}
								/>
								<label htmlFor="wireTransfer">Wire Transfer</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Use of CDFI</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientCdfiUse ? "" : "default"}>
							{client?.billingInformation.clientCdfiUse ? client?.billingInformation.clientCdfiUse : "Select option"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientCdfiUse", e.target.innerText)}>Adquisición de mercancia</Dropdown.Item>
							<Dropdown.Item onClick={(e) => handleClientChange("billingInformation", "clientCdfiUse", e.target.innerText)}>Gastos en general</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer">
					<span className="label">Payment condition</span>
					<input type="text" placeholder="Payment condition" value={client?.billingInformation.clientPaymentCondition}
						onChange={(e) => handleClientChange("billingInformation", "clientPaymentCondition", e.target.value)}
					/>
				</div>
				<div className="inputContainer">
					<span className="label">Currency</span>
					<Dropdown>
						<Dropdown.Toggle className={client?.billingInformation.clientCurrencies.length > 0 ? "" : "default"}>
							{client?.billingInformation.clientCurrencies.length > 0 ? client?.billingInformation.clientCurrencies.map(currency => currency + ", ") : "Currency"}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<div className="optionCategory">
								<input id="dollar" type="checkbox" value="Dollar" onClick={(e) => addCurrency(e.target)}
									defaultChecked={client?.billingInformation.clientCurrencies.some(currency => currency === "DOLLAR")}
								/>
								<label htmlFor="dollar">Dollar</label>
							</div>
							<div className="optionCategory">
								<input id="pesos" type="checkbox" value="Pesos" onClick={(e) => addCurrency(e.target)}
									defaultChecked={client?.billingInformation.clientCurrencies.some(currency => currency === "PESOS")}
								/>
								<label htmlFor="pesos">Pesos</label>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="inputContainer fullWidth">
					<span className="label">Notes</span>
					<textarea value={client?.billingInformation.clientNotes} rows="5"
						onChange={(e) => handleClientChange("billingInformation", "clientNotes", e.target.value)} />
				</div>

				<h5 className="sectionTitle">Contact information</h5>
				<div className="inputContainer d-lg-block d-none">
					<span className="label">Contact Name</span>
				</div>
				<div className="inputContainer d-lg-block d-none">
					<span className="label">Email</span>
				</div>
				<div className="inputContainer d-lg-block d-none">
					<span className="label">Phone</span>
				</div>
				{client?.contacts.map((contact, indexContact) => (
					<div key={indexContact} className="contactInformationRow">
						<div className="inputContainer">
							<span className="label d-lg-none">Contact Name</span>
							<input type="text" placeholder="Contact Name" value={contact.contactName}
								onChange={(e) => handleContactInformationChange(indexContact, "contactName", e.target.value)}
							/>
						</div>
						<div className="inputContainer">
							<span className="label d-lg-none">Email</span>
							<input type="text" placeholder="Contact Email" value={contact.contactEmail}
								onChange={(e) => handleContactInformationChange(indexContact, "contactEmail", e.target.value)}
							/>
						</div>
						<div className="inputContainer">
							<span className="label d-lg-none">Phone</span>
							<input type="text" placeholder="Contact Phone" value={contact.contactPhone}
								onChange={(e) => handleContactInformationChange(indexContact, "contactPhone", e.target.value)}
							/>
						</div>
						<img className="deleteIcon" src={deleteIcon} onClick={() => handleDeleteContact(indexContact)} alt="Prodensa Supplier" />
					</div>
				))}
				<button className="btnBgBlueTextGradient addContact" onClick={() => handleAddContact()}>Add Contact</button>
			</div>

			<h5 className="titleHeader">Multi-Entity</h5>
			<table className="entityTable">
				<thead>
					<tr>
						<th></th>
						<th>
							<span>Entity</span>
						</th>
						<th>
							<span>Location</span>
						</th>
					</tr>
				</thead>
				<tbody>
					{client?.entities.map((entity, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span>{index + 1 < 10 ? "0" + (index + 1) : index + 1}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{!entity.entityName ? "DEFAULT ENTITY" : entity.entityName}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>
										{!entity.entityMunicipality ? "DEFAULT MUNICIPALITY" : entity.entityMunicipality}, {!entity.entityState ? "DEFAULT STATE" : entity.entityState}, {client?.billingInformation.clientCountry}
									</span>
									<img ref={moreDotsRef} className="moreDots" src={moreDots} alt="Cattenna Platform" onClick={(e) => moreDotsRef.current = e.target} />
									<div className="menuOptions">
										<span onClick={() => handleShowEditModal(index, entity)}>Edit</span>
										{client?.entities.length > 1 && <span onClick={() => handleDeleteEntities(index)}>Delete</span>}
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="entityList d-lg-none">
				{client?.entities.map((entity, index) => (
					<div key={index} className="entityRow">
						<h5 className="entityName">{!entity.entityName ? "DEFAULT ENTITY" : entity.entityName}</h5>
						<div className="entityInformation">
							<span className="entityValue">
								{!entity.entityMunicipality ? "DEFAULT MUNICIPALITY" : entity.entityMunicipality}, {!entity.entityState ? "DEFAULT STATE" : entity.entityState}, {client?.billingInformation.clientCountry}
							</span>
							<span className="entityTitle">Location</span>
							<img ref={moreDotsRef} className="moreDots" src={moreDots} alt="Cattenna Platform" onClick={(e) => moreDotsRef.current = e.target} />
							<div className="menuOptions">
								<span onClick={() => handleShowEditModal(index, entity)}>Edit</span>
								{client?.entities.length > 1 && <span onClick={() => handleDeleteEntities(index)}>Delete</span>}
							</div>
						</div>
					</div>
				))}
			</div>
			<button className="btnBgBlueTextGradient addEntity" onClick={() => setAddModalShow(true)}>Add Entity</button>

			<button className="btnBgBlueTextGradient addClient" onClick={() => handleEditClient()}>Save Client</button>

			{client && entities && <AddEntityModal
				show={addModalShow}
				onHide={() => setAddModalShow(false)}
				setEntities={setEntities}
				country={client.billingInformation.clientCountry}
				companyName={client.billingInformation.clientCompanyName}
				rfc={client.billingInformation.clientRfc}
				state={client.billingInformation.clientState}
				postalCode={client.billingInformation.clientPostalCode}
				streetAndNumber={client.billingInformation.clientStreetAndNumber}
				neighborhood={client.billingInformation.clientNeighborhood}
				municipality={client.billingInformation.clientMunicipality}
				editView={true}
				checkEntitiesUrlName={checkEntitiesUrlName} />}

			{client && entityToEdit && <EditEntityModal
				show={editModalShow}
				onHide={() => setEditModalShow(false)}
				entityToEdit={entityToEdit}
				handleEntityChange={handleEditEntity}
				country={client.billingInformation.clientCountry}
				companyName={client.billingInformation.clientCompanyName}
				rfc={client.billingInformation.clientRfc}
				state={client.billingInformation.clientState}
				postalCode={client.billingInformation.clientPostalCode}
				streetAndNumber={client.billingInformation.clientStreetAndNumber}
				neighborhood={client.billingInformation.clientNeighborhood}
				municipality={client.billingInformation.clientMunicipality}
				editView={true}
				checkEntitiesUrlName={checkEntitiesUrlName} />}

			{client && <UsersControlModal
				show={usersControlModal}
				onHide={() => setUsersControlModal(false)}
				client={client} />}

			{client && <MassiveUploadModal
				show={showMassiveUploadModal}
				onHide={() => setShowMassiveUploadModal(false)}
				entities={client.entities} />}
		</section>
	)
}