import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../assets/css/securityStaff/singleGuardModal.css";
import close from "../../assets/images/close.svg";
import exampleProfilePhoto from "../../assets/images/profileExample.png";

const SingleGuardModal = (props) => {
    const [name, setName] = useState(props.data.name || "");
    const [profilePhoto, setProfilePhoto] = useState(props.data.profilePicture ? `${process.env.REACT_APP_URI_LOCALHOST}${props.data.profilePicture}` : exampleProfilePhoto);
    const [isChanged, setIsChanged] = useState(false);
    const staffPhotoRef = useRef(null);

    useEffect(() => {
        setName(props.data.name || "");
        setProfilePhoto(props.data.profilePicture ? `${process.env.REACT_APP_URI_LOCALHOST}${props.data.profilePicture}` : exampleProfilePhoto);
        setIsChanged(false);
    }, [props.data]);

    const handleNameChange = (e) => {
        setName(e.target.value);
        setIsChanged(true);
    };

    const handleOpenFileExplorer = () => {
        if (!staffPhotoRef.current) return;
        staffPhotoRef.current.click();
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfilePhoto(e.target.result);
                setIsChanged(true);
            };
            reader.readAsDataURL(file);
        } else {
            alert("Por favor selecciona un archivo de imagen válido.");
        }
    };

    const handleCancel = () => {
        setName(props.data.name || "");
        setProfilePhoto(props.data.profilePicture ? `${process.env.REACT_APP_URI_LOCALHOST}${props.data.profilePicture}` : exampleProfilePhoto);
        setIsChanged(false);
        props.onHide();
    };

    const handleSaveChanges = async () => {
        const entityRfc = props.data.rfc;

        const formData = new FormData();
        formData.append("rfc", entityRfc);
        formData.append("_id", props.data._id);
        formData.append("name", name);

        if (staffPhotoRef.current?.files[0]) {
            formData.append("document", staffPhotoRef.current.files[0]);
        }

        try {
            const response = await fetch(process.env.REACT_APP_URI_MODIFY_SECURITY_STAFF, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error al guardar los cambios.");
            }

            const result = await response.json();
            console.log("Guardado exitosamente:", result);

            props.fetchSecurityGuards();
            props.onHide();
        } catch (error) {
            console.error("Error:", error.message);
            alert("Ocurrió un error al guardar los cambios.");
        }
    };

    return (
        <Modal
            className="staffModal"
            size="xl"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body className="modal-body">
                <img className="closeModal" src={close} onClick={handleCancel} alt="Cerrar modal" />
                <div className="staffInformation">
                    <img className="profilePhotoModal"
                        src={profilePhoto}
                        alt="Prodensa Supplier"
                        onClick={handleOpenFileExplorer}
                    />
                    <input
                        type="file"
                        ref={staffPhotoRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handlePhotoChange}
                    />
                    <div className="employeeInformation">
                        <span className="textInfo">Security guard information</span>
                        <div className="titlePhotoMobile">
                            <img className="profilePhotoModal d-lg-none"
                                src={profilePhoto}
                                alt="Prodensa Supplier"
                                onClick={handleOpenFileExplorer}
                            />
                            <input className="textName" value={name} onChange={handleNameChange} />
                            <span className="textInfo">Name</span>
                        </div>
                        <div className="dataContainer">
                            <div>
                                <span className="textValue">{props.data.username}</span>
                                <span className="textInfo">Username</span>
                            </div>
                            <div>
                                <span className="textValue">{props.data.entity}</span>
                                <span className="textInfo">Entity</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttonsContainer">
                    <button className="btnBgWhiteTextBlack" onClick={handleCancel}>Cancel</button>
                    <button className={`editButton ${isChanged ? "" : "disabled"}`} onClick={handleSaveChanges} disabled={!isChanged}>Save changes</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SingleGuardModal;
