import "../../../assets/css/clientAlerts/actionNeededList.css"

import folder from "../../../assets/images/folder.svg"
import { FILTERS_MENU, reducer, ACTIONS, createSupplierFilter, getRouteForAlert } from "./utils.js"
import FilterButton from "./FilterButton.jsx"
import ActionNeededTable from "./ActionNeededTable.jsx"
import PageSelector from "../../pageSelector.jsx"
import eyeIcon from "../../../assets/images/viewProfile.svg"
import dots from "../../../assets/images/moreDots.svg"

import Axios from "axios"
import Swal from "sweetalert2"
import { useEffect, useReducer } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"

export default function ActionNeededList({ suppliersActionNeeded, searchValue, setSearchValue, userLogged, darkLightMode }) {
    const [state, dispatch] = useReducer(reducer, {
        openFilter: null,
        activeFilters: [],
        filtersOptions: {
            supplier: [],
            typeOfAlert: []
        },
        sortOrder: "ASC",
        sortBy: "supplier",
        originalAlerts: JSON.parse(JSON.stringify(suppliersActionNeeded)),
        alerts: suppliersActionNeeded.filter(action => !action.completedBy),
        currentPage: 1,
        showHistoric: false,
        moreOptionsOpen: null
    })
    
    // VARIABLE TO MANAGE THE NAVIGATE TO OTHER COMPONENTS
    const navigate = useNavigate()

    // useEffect to add body listener
    useEffect(() => {
        document.body.addEventListener("click", closeFilters)
        document.body.addEventListener("click", closeMoreOptions)

        return () => {
            document.body.removeEventListener("click", closeFilters)
            document.body.removeEventListener("click", closeMoreOptions)
        }
    }, [])

    useEffect(() => {
        dispatch({ type: ACTIONS.handleGlobalSearch, payload: searchValue })
    }, [searchValue])

    const handleActionCompleted = (action) => {
        const updatedAction =  {...action }

        updatedAction.completedBy = updatedAction.completedBy ? null : userLogged.username

        const data = {
            updatedAction,
            userLogged
        }

        Swal.fire({
            toast: true,
            footer: 'Loading, please wait',
            showConfirmButton: false,
            position: "bottom-end",
            didOpen: () => {
                Swal.showLoading()
            },
        })

        Axios.patch(process.env.REACT_APP_CREATE_ACTION, data).then(res => {
            const dataNeeded = {
                alerts: res.data,
                searchValue
            }

            dispatch({ type: ACTIONS.handleActionCompleted, payload: dataNeeded })

            Swal.close()
        }).catch(err => {
            console.log(err)
            Swal.close()
        })
    }

    function handleShowHistoric() {
        dispatch({ type: ACTIONS.handleShowHistoric, payload: searchValue })
    }

    let totalPages = Math.ceil(state.alerts.length / 20);

    function paginate(pageNumber) {
        if (pageNumber > totalPages) return

        dispatch({ type: ACTIONS.handleCurrentPage, payload: pageNumber })
    };

    function closeFilters(e) {
        if (!e.target.classList.contains("actionNeededFilter")) {
            dispatch({ type: ACTIONS.setOpenFilter, payload: null })
        }
    }
    
    function closeMoreOptions(e) {
        if (!e.target.classList.contains("alerts-more-dots")) {
            dispatch({ type: ACTIONS.handleMoreOptions, payload: null })
        }
    }

    const handleSupplierClick = (alert) => {
        const routeForAlert = getRouteForAlert(alert)

        navigate(routeForAlert)
    }

    const handleMoreOptions = (alert, e) => {
        e?.stopPropagation()
        
        dispatch({ type: ACTIONS.handleMoreOptions, payload: alert._id })
    }

    return (
        <section className={`actionNeeded ${darkLightMode ? "darkMode" : null}`}>
            <div className="filters">
                <div className="filtersButtons">
                    {FILTERS_MENU.map(filter => {
                        if (filter.accessKey === "supplier") {
                            filter.items = createSupplierFilter(state?.originalAlerts)
                        }
                        
                        return (
                            <FilterButton 
                                key={filter.id}
                                item={filter}
                                state={state}
                                dispatch={dispatch}
                                setSearchValue={setSearchValue}
                            />
                        )
                    })}
                </div>

                <label className="switch">
                    <span id="label-on" className={`insideLabel ${state?.showHistoric ? "" : "hidden"}`}>View log</span>
                    <span id="label-off" className={`insideLabel ${state?.showHistoric ? "hidden" : ""}`}>View log</span>
                    <input type="checkbox" checked={state?.showHistoric} onChange={handleShowHistoric} />
                    <span className={`slider round ${state?.showHistoric ? "isChecked" : ""}`}></span>
                </label>
            </div>

            <ActionNeededTable
                state={state}
                dispatch={dispatch}
                handleActionCompleted={handleActionCompleted}
                handleMoreOptions={handleMoreOptions}
                darkLightMode={darkLightMode}
            />

            <div className="d-lg-none actionNeededList">
                {state?.alerts?.map((supplier, index) => {
                    const property = supplier.typeOfAction?.includes("Document") ? "documentName" : "clientName"
                    const dinamicText = supplier.typeOfAction?.includes("Document") ? "Document name" : "Client name"

                    const isOptionsOpen = state?.moreOptionsOpen === supplier._id
                    const actionLabel = supplier.completedBy ? "Mark as pending" : "Mark as completed"
                    
                    return (
                        <div key={index} className="actionNeededRow" onClick={() => handleSupplierClick(supplier)}>
                            <h5 className="actionNeededName">
                                {supplier.companyName}
    
                                <div className="mobileActionsContainer">
                                    <img className="alerts-more-dots" src={dots} alt="Prodensa Supplier" onClick={(e) => handleMoreOptions(supplier, e)}/>
                                    
                                    <div className={`optionsContainer ${isOptionsOpen ? "visible" : null}`}>
                                        <span onClick={() => handleSupplierClick(supplier)}>View action</span>
                                        <span onClick={() => handleActionCompleted(supplier)}>{actionLabel}</span>
                                    </div>
                                </div>
                            </h5>
                            <div className="actionNeededInformation">
                                <div className="actionInfoContainer">
                                    <span className="actionNeededValue">{supplier.typeOfAction}</span>
                                    <span className="actionNeededTitle">Type Of Action</span>
                                </div>
    
                                <div className="actionInfoContainer">
                                    <span className="actionNeededValue">{moment(supplier.createdAt).format("DD MMMM YYYY")}</span>
                                    <span className="actionNeededTitle">Created At</span>
                                </div>
    
                                {supplier?.[property] && <div className="actionInfoContainer" id="idDocInfoMobile">
                                    <span className="actionNeededValue">{supplier?.[property]}</span>
                                    <span className="actionNeededTitle">{dinamicText}</span>
                                </div>}
    
                                <div className="actionInfoContainer">
                                    <span className="actionNeededValue" id="completedByUsername">{supplier.completedBy ?? "Not completed yet"}</span>
                                    <span className="actionNeededTitle">Completed By</span>
                                </div>
    
                                {supplier.completedBy && <div className="actionInfoContainer">
                                    <span className="actionNeededValue">{moment(supplier.updatedAt).format("DD MMMM YYYY hh:m A")}</span>
                                    <span className="actionNeededTitle">Completed At</span>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="addAndPagination">
                <PageSelector
                    currentPage={state.currentPage}
                    data={state.alerts}
                    itemsPerPage={35}
                    onChange={paginate}
                />
            </div>
            <span className="tableCount">{state.alerts?.length < 34 ? state.alerts?.length : 35 * state.currentPage} of {state.alerts?.length}</span>
        </section>
    )
}