import "../../assets/css/tabs/qualitySurvey.css"

import qualitySurveyJson from "../../assets/jsonData/qualitySurvey.json"
import certificationList from "../../assets/jsonData/certificationList.json"

import deleteIcon from "../../assets/images/delete.svg"
import arrowMenu from "../../assets/images/arrowForward.svg"
import close from "../../assets/images/close.svg"

import React, { useEffect, useRef, useState } from "react"
import Swal from "sweetalert2"

export default function QualitySurvey(props) {
	// VARIABLE TO MANAGE THE QUALITY SURVEY DATA
	const qualitySurveyCopy = props.qualitySurvey

	// VARIABLE TO MANAGE THE COPY CERTIFICATION LIST
	const [certificationListCopy, setCertificationListCopy] = useState(null)

	// REFERENCE TO MANAGE THE CERTIFICATION DROPDOWNS
	const certificationMenuRef = useRef(null)

	useEffect(() => {
		setCertificationListCopy(certificationList)

		//CONDITION TO KNOW IFTHE SUPPLIER ALREADY HAVE THE QUALITY SURVEY
		if (qualitySurveyCopy.questions.length < 1) {
			props.setSupplier(prevState => {
				return {
					...prevState,
					qualitySurvey: {
						...prevState.qualitySurvey,
						questions: qualitySurveyJson.questions
					}
				}
			})
		}

		document.body.addEventListener("click", closeMenus)

		return function cleanUp() {
			document.body.removeEventListener("click", closeMenus)
		}
	}, [])

	// FUNCTION TO HANDLE THE DROPDOWN CLOSE
	const closeMenus = (e) => {
		if (certificationMenuRef.current && certificationMenuRef.current.contains(e.target)) {
			const allMenus = Array.prototype.slice.call(document.getElementsByClassName("certificationMenu"))

			allMenus.map(element => {
				element.classList.remove("show")
			})

			certificationMenuRef.current.nextElementSibling.classList.toggle("show")
		} else {
			const allMenus = Array.prototype.slice.call(document.getElementsByClassName("certificationMenu"))

			allMenus.map(element => {
				element.classList.remove("show")
			})
		}
	}

	// FUNCTION TO HANDLE THE ADD CERTIFICATION
	const handleAddCertification = () => {
		qualitySurveyCopy.certifications.push({
			certificationName: "",
			registryNumber: "",
			organizationName: "",
			expeditionDate: "",
			expiredDate: "",
			certification: {
				document: null,
				uploadedAt: null,
				yearCreatedAt: null,
				monthCreatedAt: null,
				dayCreatedAt: null,
			}
		})

		props.setSupplier(prevState => {
			return {
				...prevState,
				qualitySurvey: qualitySurveyCopy
			}
		})
	}

	// FUNCTION TO HANDLE THE DELETE CERTIFICATION
	const handleDeleteCertification = (position) => {
		qualitySurveyCopy.certifications.splice(position, 1)

		props.setSupplier(prevState => {
			return {
				...prevState,
				qualitySurvey: qualitySurveyCopy
			}
		})
	}

	// FUNCTION TO HANDLE THE CHANGE OF THE CERTIFICATIONS
	const handleCertificationChange = (position, property, value) => {
		if (property === "certification") {
			// In this case value have the element target
			if (value && value.files[0]) {
				if (value.files[0].type === "application/pdf") {
					qualitySurveyCopy.certifications[position][property].document = value.files[0]
				} else {
					value.value = null
					qualitySurveyCopy.certifications[position][property].document = null

					Swal.fire({
						icon: 'error',
						text: 'The file is not a PDF.',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
				}
			} else {
				qualitySurveyCopy.certifications[position][property].document = null
			}
			
			if (value) {
				value.value = null
			}
			
			props.handleSaveChanges({
				...props.supplier,
				qualitySurvey: qualitySurveyCopy
			})
		} else {
			qualitySurveyCopy.certifications[position][property] = value

			props.setSupplier(prevState => {
				return {
					...prevState,
					qualitySurvey: qualitySurveyCopy
				}
			})
		}
	}

	// FUNTION TO VALIDATE IF THE CERTIFICATION EXIST
	const validateCertification = (value, position) => {
		if (value !== "" && !certificationList.some(certification => certification === value)) {
			handleFilterList("", position)
			Swal.fire({
				icon: 'error',
				text: 'Please select a certification from our list',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		}
	}

	// FUNCTION TO HANDLE THE CHANGE OF THE QUESTION
	const handleQuestionChange = (position, property, value) => {
		qualitySurveyCopy.questions[position][property] = value

		props.setSupplier(prevState => {
			return {
				...prevState,
				qualitySurvey: qualitySurveyCopy
			}
		})
	}

	// FUNCTION TO HANDLE THE CHANGE OF THE QUESTION
	const handleSubQuestionChange = (positionQuestion, positionSubQuestion, property, value) => {
		qualitySurveyCopy.questions[positionQuestion].subQuestions[positionSubQuestion][property] = value

		props.setSupplier(prevState => {
			return {
				...prevState,
				qualitySurvey: qualitySurveyCopy
			}
		})
	}

	// FUNCTION TO FILTER THE CERTIFICATION LIST
	const handleFilterList = (textRecived, position) => {
		const list = certificationList.filter(listValue => listValue.includes(textRecived))

		setCertificationListCopy(list)
		handleCertificationChange(position, "certificationName", textRecived)
	}

	return (
		<section className="qualitySurvey">
			<div className="titleAndActions">
				<h5 className="titleSection">Certifications</h5>
			</div>
			{qualitySurveyCopy && qualitySurveyCopy.certifications.map((certification, indexCertification) => (
				<div key={indexCertification} className="inputSections">
					<div className="inputContainer" data-name="certificationInput">
						<span className="label">Certification</span>
						<input value={certification.certificationName} type="text" placeholder="Type a certification"
							onChange={(e) => handleFilterList(e.target.value, indexCertification)}
							onFocus={(e) => { certificationMenuRef.current = e.target; setCertificationListCopy(certificationList) }}
							onBlur={(e) => validateCertification(e.target.value, indexCertification)}
						/>
						<div className="certificationMenu">
							{certificationListCopy?.map((certification, index) => (
								<span className="optionMenu" key={index}
									onMouseDown={() => {
										handleCertificationChange(indexCertification, "certificationName", certification);
										setCertificationListCopy(certificationList);
									}}>
									{certification}
								</span>
							))}
						</div>
						<img className="arrowMenu" src={arrowMenu} alt="Prodensa Supplier" />
						<img className="deleteIcon d-lg-none" src={deleteIcon} onClick={() => handleDeleteCertification(indexCertification)} alt="Prodensa Supplier" />
					</div>
					<div className="inputContainer">
						<span className="label">Registry number</span>
						<input value={certification.registryNumber} type="text" placeholder="Registry number"
							onChange={(e) => handleCertificationChange(indexCertification, "registryNumber", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Certification organization name</span>
						<input value={certification.organizationName} type="text" placeholder="Certification organization name"
							onChange={(e) => handleCertificationChange(indexCertification, "organizationName", e.target.value)}
						/>
					</div>
					<div className="inputContainer calendar">
						<span className="label">Expedition date</span>
						<input value={certification.expeditionDate} type="date"
							onChange={(e) => handleCertificationChange(indexCertification, "expeditionDate", e.target.value)}
						/>
					</div>
					<div className="inputContainer calendar">
						<span className="label">Expired date</span>
						<input value={certification.expiredDate} type="date"
							onChange={(e) => handleCertificationChange(indexCertification, "expiredDate", e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Certification document</span>
						<div className="fileContainer">
							{certification.certification.document
								? <React.Fragment>
									<a className="downloadFile btnBgBlueTextGradient"
										href={process.env.REACT_APP_URI_UPLOADS +
											props.rfc + "/" +
											(certification.certification.document.name ?? certification.certification.document)}
										target="_blank">View attached file</a>
									<span className="fileName">{certification.certification.document.name ?? certification.certification.document}</span>
									<img className="close" src={close} alt="Prodensa Supplier"
										onClick={() => handleCertificationChange(indexCertification, "certification", "")} />
								</React.Fragment>
								: <input type="file" accept=".pdf"
									onChange={(e) => handleCertificationChange(indexCertification, "certification", e.target)}
								/>}
						</div>
					</div>
					<div className="inputContainer d-none d-lg-flex moreWidth">
						<img className="deleteIcon" src={deleteIcon} onClick={() => handleDeleteCertification(indexCertification)} alt="Prodensa Supplier" />
					</div>
				</div>
			))}
			<button className="btnBgBlueTextGradient" onClick={() => handleAddCertification()}>Add certification</button>

			<div className="titleAndActions">
				<h5 className="titleSection">Questions <bg-grey>(If applicable)</bg-grey></h5>
			</div>
			{qualitySurveyCopy && qualitySurveyCopy.questions.map((question, indexQuestion) => (
				<div key={indexQuestion} className="inputSections">
					<div className="titleAndActions">
						<h5 className="titleQuestion">{question.question}</h5>
					</div>
					{question.typeQuestion === "Radio" && <div className="inputContainer radio">
						<input id={`questionYes${indexQuestion}`} type="radio" name={`question${indexQuestion}`}
							checked={question.answer ? true : false}
							onChange={() => handleQuestionChange(indexQuestion, "answer", true)}
						/>
						<label htmlFor={`questionYes${indexQuestion}`}>Yes</label>
						<input id={`questionNo${indexQuestion}`} type="radio" name={`question${indexQuestion}`} className="no"
							checked={question.answer ? false : true}
							onChange={() => handleQuestionChange(indexQuestion, "answer", false)}
						/>
						<label htmlFor={`questionNo${indexQuestion}`}>No</label>
					</div>}

					{question.typeQuestion === "Text" && <div className="inputContainer moreWidth">
						<input type="text" value={question.answer}
							onChange={(e) => handleQuestionChange(indexQuestion, "answer", e.target.value)}
						/>
					</div>}

					{(question.answer || !question.typeQuestion) && question.subQuestions.map((subQuestion, indexSubQuestion) => (
						<React.Fragment key={indexSubQuestion}>
							{subQuestion.typeQuestion === "Radio" && <div className="inputContainer radio">
								<span className="label">{subQuestion.question}</span>
								<input id={`questionYes${indexQuestion}subQuestionYes${indexSubQuestion}`} type="radio"
									name={`question${indexQuestion}subQuestion${indexSubQuestion}`}
									checked={subQuestion.answer ? true : false}
									onChange={() => handleSubQuestionChange(indexQuestion, indexSubQuestion, "answer", true)}
								/>
								<label htmlFor={`questionYes${indexQuestion}subQuestionYes${indexSubQuestion}`}>Yes</label>
								<input id={`questionNo${indexQuestion}subQuestionNo${indexSubQuestion}`} type="radio"
									name={`question${indexQuestion}subQuestion${indexSubQuestion}`} className="no"
									checked={subQuestion.answer ? false : true}
									onChange={() => handleSubQuestionChange(indexQuestion, indexSubQuestion, "answer", false)}
								/>
								<label htmlFor={`questionNo${indexQuestion}subQuestionNo${indexSubQuestion}`}>No</label>
							</div>}

							{subQuestion.typeQuestion === "Text" && <div className="inputContainer moreWidth">
								<div className="w-100"></div>
								<span className="label">{subQuestion.question}</span>
								<input type="text"
									onChange={(e) => handleSubQuestionChange(indexQuestion, indexSubQuestion, "answer", e.target.value)}
								/>
							</div>}
						</React.Fragment>
					))}
				</div>
			))}
		</section >
	)
}