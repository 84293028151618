import "../../assets/css/clientsAdminView/addEntity.css"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import close from "../../assets/images/close.svg"

import { Dropdown, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { checkTheEmail } from "../../utilities/validateEmail";
import Swal from "sweetalert2";

export default function AddEntityModal(props) {
	// VARIABLES TO MANAGE THE ENTITY INFORMATION
	const [entityName, setEntityName] = useState("")
	const [entityUrlName, setEntityUrlName] = useState("")
	const [entityRfc, setEntityRfc] = useState("")
	const [entityState, setEntityState] = useState("")
	const [entityPostalCode, setEntityPostalCode] = useState("")
	const [entityStreetAndNumber, setEntityStreetAndNumber] = useState("")
	const [entityNeighborhood, setEntityNeighborhood] = useState("")
	const [entityMunicipality, setEntityMunicipality] = useState("")
	const [entityNotificationContacts, setEntityNotificationContacts] = useState("")
	const [entityUsername, setEntityUsername] = useState("")

	useEffect(() => {
		if (props.show) {
			setEntityName(props.companyName)
			setEntityRfc(props.rfc)
			setEntityState(props.state)
			setEntityPostalCode(props.postalCode)
			setEntityStreetAndNumber(props.streetAndNumber)
			setEntityNeighborhood(props.neighborhood)
			setEntityMunicipality(props.municipality)
		}
	}, [props.show])

	// FUNCTION TO HANDLE THE MODAL CLOSE
	const handleCloseModal = () => {
		setEntityName("")
		setEntityUrlName("")
		setEntityRfc("")
		setEntityState("")
		setEntityPostalCode("")
		setEntityStreetAndNumber("")
		setEntityNeighborhood("")
		setEntityMunicipality("")
		setEntityNotificationContacts("")
		setEntityUsername("")
		props.onHide()
	}

	// FUNCTION TO HANDLE THE CLIENT CREATION
	const handleEntityCreation = () => {
		props.setEntities(prevState => {
			return [...prevState, {
				entityName: entityName,
				entityUrlName: entityUrlName,
				entityRfc: entityRfc,
				entityState: entityState,
				entityPostalCode: entityPostalCode,
				entityStreetAndNumber: entityStreetAndNumber,
				entityNeighborhood: entityNeighborhood,
				entityMunicipality: entityMunicipality,
				entityNotificationContacts: entityNotificationContacts,
				entityUsername: entityUsername,
			}]
		})

		handleCloseModal()
	}

	// FUNCTION TO CHECK IF THE URL NAME EXIST
	const checkEntityUrlName = async (urlNameToCheck) => {
		const found = await props.checkEntitiesUrlName(urlNameToCheck)

		if (found) {
			Swal.fire({
				icon: 'error',
				text: 'This url name already exist, please try another one',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			setEntityUrlName("")
		}
	}

	const handleEmailCheck = async () => {
		try {
			const res = await checkTheEmail(entityUsername)

			setEntityUsername(res)
		} catch (error) {
			console.log(error)
			setEntityUsername("")
		}
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="xl"
			centered
			className="entityModal">

			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => handleCloseModal()} alt="Prodensa Supplier" />
				<h3 className="modalTitle">Add entity</h3>
				<div className="inputSections">
					<div className="inputContainer">
						<span className="label">Name</span>
						<input type="text" placeholder="Entity name" value={entityName}
							onChange={(e) => setEntityName(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Cattenna Landing Name</span>
						<input type="text" placeholder="Cattenna Landing Name" value={entityUrlName}
							onChange={(e) => setEntityUrlName(e.target.value)}
							onBlur={() => checkEntityUrlName(entityUrlName)}
						/>
					</div>
					<div className={`inputContainer`}>
						<span className="label">
							{countryInformation.find(countries => {
								return countries.country.toUpperCase() === props.country.toUpperCase()
							})?.rfcLabel}
						</span>
						<input id="inputRfc" value={entityRfc}
							onChange={(e) => {
								if (props.country === "México")
									setEntityRfc(e.target.value)
								else if (props.country === "United states of america") {
									const value = e.target.value.replace(/[^\d]/g, "")
									setEntityRfc(value)
								}
							}}
							type="text"
							placeholder={countryInformation.find(countries => {
								return countries.country.toUpperCase() === props.country.toUpperCase()
							})?.rfcPlaceholder} />
					</div>
					<div className="inputContainer">
						<span className="label">State</span>
						<Dropdown>
							<Dropdown.Toggle className={`${entityState ? "" : "default"}`}>
								{entityState ? entityState : "State"}
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{countryInformation.find(countries => countries.country.toUpperCase() === props.country.toUpperCase())?.states.map((state, index) => (
									<Dropdown.Item key={index} onClick={(e) => setEntityState(e.target.innerText)}>{state.name}</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
					<div className="inputContainer">
						<span className="label">C.P:</span>
						<input type="text" placeholder="Postal code" value={entityPostalCode}
							onChange={(e) => setEntityPostalCode(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Street, interior & exterior number</span>
						<input type="text" placeholder="Street, interior & exterior number" value={entityStreetAndNumber}
							onChange={(e) => setEntityStreetAndNumber(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Neighborhood</span>
						<input type="text" placeholder="Neighborhood" value={entityNeighborhood}
							onChange={(e) => setEntityNeighborhood(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Municipality</span>
						<input type="text" placeholder="Municipality" value={entityMunicipality}
							onChange={(e) => setEntityMunicipality(e.target.value)}
						/>
					</div>
					<div className="inputContainer">
						<span className="label">Notification contacts <bg-grey>(Separate by comma)</bg-grey></span>
						<input type="text" placeholder="example1@example.com, example2@example.com" value={entityNotificationContacts}
							onChange={(e) => setEntityNotificationContacts(e.target.value)}
						/>
					</div>
					{!props.editView && <div className="inputContainer">
						<span className="label">Entity username</span>
						<input type="text" placeholder="example@example.com" value={entityUsername}
							onChange={(e) => setEntityUsername(e.target.value)}
							onBlur={handleEmailCheck}
						/>
					</div>}
				</div>
				<div className="actions">
					<button className="btnBgWhiteTextBlack" onClick={() => handleCloseModal()}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={() => handleEntityCreation()}>Add entity</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}