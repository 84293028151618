import "../assets/css/pages/maintenance.css"
import world from "../assets/images/world.webp";
import logo from "../assets/images/logoProdensa.png";
import { Col, Row } from "react-bootstrap";

function Maintenance() {

	return (
		<section className="maintenance">
			<div className="container">
				<Row>
					<Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 6 }}>
						<img className="world" src={world} alt="Prodensa Cattenna Portal" />
						<img className="logo" src={logo} alt="Prodensa Cattenna Portal" />
						<h2>Server under maintenance</h2>
						<span>We’ll be back shortly</span>
					</Col>
				</Row>
			</div >

		</section >
	)
}

export default Maintenance;