import "../../assets/css/clientsSupplierView/clientContractList.css"

import sortIcon from "../../assets/images/sort.svg"
import next from "../../assets/images/arrowForward.svg"
import reviewed from "../../assets/images/viewProfile.svg"

import { useState } from "react"
import moment from "moment"

const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function ClientContractList(props) {
	// VARIABLE TO CONTROL DATA FROM TABLE
	const [dataTable, setDataTable] = useState(props.clientContractList ?? [])
	const [dataTableCopy, setDataTableCopy] = useState(props.clientContractList ?? [])

	// VARIABLE TO MANAGE THE SUPPLIER RFC
	const { supplierRFC } = props

	const [currentPage, setCurrentPage] = useState(1)

	let totalPages = Math.ceil(dataTableCopy.length / 20);
	let indexOfLastItem = +currentPage * 20;
	let indexOfFirstItem = indexOfLastItem - 20;
	let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

	let paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		// ORDER FALSE = ASC; ORDER TRUE = DSC 
		switch (sortBy) {
			case "ContractName":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.contractName.toLocaleLowerCase();
					let fb = b.contractName.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "ContractStatus":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.contractStatus.toLocaleLowerCase();
					let fb = b.contractStatus.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Expedition":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let da = new Date(a.contractExpdition);
					let db = new Date(b.contractExpdition);
					if (!order) {
						return da - db
					} else {
						return db - da
					}
				}))

				setOrder(!order)
				break

			case "Expiration":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let da = new Date(a.contractExpire);
					let db = new Date(b.contractExpire);
					if (!order) {
						return da - db
					} else {
						return db - da
					}
				}))

				setOrder(!order)
				break

			default:
				break;
		}
	}

	// FUNCTION TO DOWNLOAD THE CONTRACT FILE
	const downloadContractFile = (contractPath) => {
		const fileString = URI_UPLOADS + supplierRFC + "/" + contractPath
		window.open(fileString, "_blank")
	}

	return (
		<section className="clientContracts">
			<table className="clientTableContracts">
				<thead>
					<tr>
						<th>
							<span>Contract Name</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "ContractName")} />
						</th>
						<th>
							<span>Contract Status</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "ContractStatus")} />
						</th>
						<th>
							<span>Expedition</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Expedition")} />
						</th>
						<th>
							<span>Expiration</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Expiration")} />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map((contract, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span onClick={() => contract.contractDocument ? downloadContractFile(contract.contractDocument) : undefined}>
										{contract.contractName}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className={`${contract.contractStatus === "Active" ? "successAlone" : "failedAlone"}`}>
										{contract.contractStatus}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{moment(contract.contractExpdition).format("DD MMMM YYYY")}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{moment(contract.contractExpire).format("DD MMMM YYYY")}</span>
									{contract.contractDocument && <img className="reviewed" src={reviewed} alt="Prodensa Supplier" onClick={() => downloadContractFile(contract.contractDocument)} />}
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none clientContractList">
				{currentItems.map((contract, index) => (
					<div key={index} className="contractRow">
						<h5 className="contractName">
							{contract.contractName}
							{contract.contractDocument && <img className="reviewed" src={reviewed} alt="Prodensa Supplier" onClick={() => contract.contractDocument ? downloadContractFile(contract.contractDocument) : undefined} />}
						</h5>
						<div className="contractInformation">
							<span className={`contractValue ${contract.contractStatus === "Active" ? "successAlone" : "failedAlone"}`}>{contract.contractStatus}</span>
							<span className="contractTitle">Contract Status</span>
							<span className="contractValue">{moment(contract.contractExpedition).format("DD MMMM YYYY")}</span>
							<span className="contractTitle">Expedition</span>
							<span className="contractValue">{moment(contract.contractExpire).format("DD MMMM YYYY")}</span>
							<span className="contractTitle">Expiration</span>
						</div>
					</div>
				))}
			</div>

			<div className="addAndPagination">
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {dataTable.length}</span>
		</section>
	)
}