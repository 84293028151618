import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import ReactDOM from 'react-dom/client';
import { CookiesProvider } from "react-cookie"
import App from "./app";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<CookiesProvider defaultSetOptions={{ path: "/" }}>
		<App />
	</CookiesProvider>
);

