import { useState } from "react"
import { ACTIONS } from "./utils.js"

export default function FilterButton(props) {
    const {
        item,
        state,
        dispatch,
        setSearchValue
    } = props

    const {
        icon,
        title,
        accessKey,
        items,
    } = item

    const [search, setSearch] = useState("")

    function handleItems(e, property, value) {
        e.stopPropagation()

        dispatch({ type: ACTIONS.setFilterOptions, payload: { property, value } })
    }

    function handleSearch(e) {
        const { value } = e.target
        
        setSearch(value)
    }

    const filterClass = `actionNeededFilter ${state.activeFilters.includes(accessKey) ? "active" : ""} ${state.openFilter === accessKey ? "show" : ""}`.trim()
    const itemsClass = `actionNeededFilterOptions ${state.openFilter === accessKey ? "show" : ""}`.trim()

    return (
        <div className={filterClass} onClick={() => dispatch({ type: ACTIONS.setOpenFilter, payload: accessKey })} >
            <img src={icon} alt="Prodensa Supplier" />

            <span>{title}</span>
            
            <div className={itemsClass} >
                <span className="title" onClick={(e) => e.stopPropagation()}>
                    <img className="d-lg-none imgMenu" src={icon} alt="Prodensa Supplier" />

                    {title}
                </span>

                {accessKey === "supplier" && 
                    <div className="searchContainer" onClick={(e) => e.stopPropagation()}>
                        <input type="text" value={search} onChange={handleSearch} />
                    </div>
                }
                
                <div className="optionsScroll" onClick={(e) => e.stopPropagation()}>
                    {items?.filter(item => search === "" || item?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())).map((item) => {
                        const isOptionChecked = state?.filtersOptions?.[accessKey]?.includes(item)
                        
                        return (
                            <div key={`${accessKey}-${item}`} className="option" >
                                <input 
                                    className="supplierCheck" 
                                    type="checkbox" 
                                    id={`${accessKey}-${item}`} 
                                    checked={isOptionChecked}
                                    onChange={(e) => handleItems(e, accessKey, item)}
                                />

                                <label 
                                    htmlFor={`${accessKey}-${item}`} 
                                >
                                    {item}
                                </label>
                            </div>
                        )
                    })}
                </div>
                
                <div className="actions">
                    <button onClick={() => dispatch({ type: ACTIONS.setClearFilter, payload: accessKey }) }>Clear</button>
                    <button onClick={() => { setSearchValue(""); dispatch({ type: ACTIONS.setActiveFilter, payload: accessKey }) } }>Apply</button>
                </div>
            </div>
        </div>
    )
}
