import "../../assets/css/requests/reviewForAuth.css"

import back from "../../assets/images/arrowForward.svg"
import save from "../../assets/images/save.svg"
import cvpBadge from "../../assets/images/CVPBagdeProfile.png"
import starRating from "../../assets/images/starRating.svg"
import profileExample from "../../assets/images/profileExample.png"

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import InformationCompliance from "../tabs/informationCompliance.jsx";
import QualitySurvey from "../tabs/qualitySurvey.jsx";
import DocumentComplianceTable from "../tabs/documentCompliance/documentComplianceTable.jsx"

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swal from "sweetalert2"
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import ProductsAndServices from "../tabs/productAndService.jsx";
import Contracts from "../tabs/contracts.jsx";
import StaffList from "../tabs/staff/staffList.jsx"
import { removeDocumentNoRepse } from "../tabs/documentCompliance/utils.js"
import { checkInformationCompliance } from "../../utilities/utils.js"

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS
const URI_SERVER_DATE = process.env.REACT_APP_URI_SERVER_DATE
const URI_USERS = process.env.REACT_APP_URI_USERS_FILTER_BY_DATA_ID
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT
const URI_NOTIFICATION = process.env.REACT_APP_URI_SEND_NOTIFICATION

// MODAL TO SHOW THE CLIENT LIST
function SupplierClientsModalReviewForAuth(props) {
	const navigate = useNavigate()

	async function handleAction(supplier, clientID, status) {
		const res = await props.handleSaveInfo(supplier, clientID, status)

		if (res) {
			const data = {
				username: props.userLogged?.username,
				supplierID: supplier._id,
				status: "Ready for review",
				clientID: clientID
			}
	
			axios.patch(process.env.REACT_APP_CREATE_ACTION, data).then().catch(err => {
				console.log(err)
			})
		}
	}

	return (
		<Modal
			className="supplierClientsModalReviewForAuth"
			size="lg"
			centered
			show={props.show}
			onHide={props.onHide}
		>
			<Modal.Body>
				<h5 className="commentTitle">Clients</h5>
				<table className="tableClients">
					<thead>
						<tr>
							<th><span>Name</span></th>
							<th><span>Status</span></th>
							<th><span className="w-100" style={{ justifyContent: "flex-end" }}>Actions</span></th>
						</tr>
					</thead>
					<tbody>
						{props.supplierClients && props.supplierClients.map((supplierClient, index) => {
							switch (props.userLogged.rol) {
								case "Master":
								case "Super admin":
									return (
										<tr key={index}>
											<td>
												<div className="infoContainer">
													<span>{props.clients && props.clients.find(clients => clients.entities.find(entity => entity._id === supplierClient.clientID)).entities.find(entity => entity._id === supplierClient.clientID).entityName}</span>
												</div>
											</td>
											<td>
												<div className="infoContainer">
													<span className={`${supplierClient.status === "Ready for review" || supplierClient.status === "Active" ? "success" : ""} ${supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ? "pending" : ""} ${supplierClient.status === "New request" ? "new" : ""}`}>
														{supplierClient.status}
													</span>
												</div>
											</td>
											<td>
												<div className="infoContainer">
													<span className="actions">
														{supplierClient.status === "Ready for review" || supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ?
															<>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="activeTooltip">
																			Active
																		</Tooltip>
																	}
																>
																	<div onClick={() => handleAction(props.supplier, supplierClient.clientID, "Active")} />
																</OverlayTrigger>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="authorizeTooltip">
																			Authorize
																		</Tooltip>
																	}
																>
																	<div onClick={() => handleAction(props.supplier, supplierClient.clientID, "Authorized")} />
																</OverlayTrigger>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="rejectTooltip">
																			Reject
																		</Tooltip>
																	}
																>
																	<div onClick={() => handleAction(props.supplier, supplierClient.clientID, "Rejected")} />
																</OverlayTrigger>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="reviewTooltip">
																			Request Review
																		</Tooltip>
																	}
																>
																	<div onClick={() => handleAction(props.supplier, supplierClient.clientID, "Review requested")} />
																</OverlayTrigger>
															</> :
															<React.Fragment>
																{"No actions required"}
																<button className="btnBgBlueTextGradient" onClick={() => navigate("/home/suppliers/supplierProfile/" + props.supplier._id)}>
																	Profile</button>
															</React.Fragment>
														}
													</span>
												</div>
											</td>
										</tr>
									)

								case "Admin client":
									const clientEntitiesIDs = props.clients && props.clients.find(client => client._id.toString() === props.userLogged.dataID.toString()).entities.map(entity => entity._id.toString())
									return (
										clientEntitiesIDs && clientEntitiesIDs.includes(supplierClient.clientID) && <tr key={index}>
											<td>
												<div className="infoContainer">
													<span>{props.clients && props.clients.find(clients => clients.entities.find(entity => entity._id === supplierClient.clientID)).entities.find(entity => entity._id === supplierClient.clientID).entityName}</span>
												</div>
											</td>
											<td>
												<div className="infoContainer">
													<span className={`${supplierClient.status === "Ready for review" || supplierClient.status === "Active" ? "success" : ""} ${supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ? "pending" : ""} ${supplierClient.status === "New request" ? "new" : ""}`}>
														{supplierClient.status}
													</span>
												</div>
											</td>
											<td>
												<div className="infoContainer">

													<span className="actions">
														{supplierClient.status === "Ready for review" || supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ?
															<>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="activeTooltip">
																			Active
																		</Tooltip>
																	}
																>
																	<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Active")} />
																</OverlayTrigger>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="authorizeTooltip">
																			Authorize
																		</Tooltip>
																	}
																>
																	<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Authorized")} />
																</OverlayTrigger>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="rejectTooltip">
																			Reject
																		</Tooltip>
																	}
																>
																	<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Rejected")} />
																</OverlayTrigger>
																<OverlayTrigger placement="top"
																	overlay={
																		<Tooltip id="reviewTooltip">
																			Request Review
																		</Tooltip>
																	}
																>
																	<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Review requested")} />
																</OverlayTrigger>
															</> :
															"No actions required"
														}
													</span>
												</div>
											</td>
										</tr>
									)

								default:
									break;
							}
						})}
					</tbody>
				</table>

				<div className="d-lg-none mobileCientList">
					{props.supplierClients && props.supplierClients.map((supplierClient, index) => {
						switch (props.userLogged.rol) {
							case "Master":
							case "Super admin":
								return (
									<div key={index} className="clientRow">
										<span className="clientHeaderTitle">{props.clients.find(clients => clients.entities.find(entity => entity._id === supplierClient.clientID)).entities.find(entity => entity._id === supplierClient.clientID).entityName}</span>
										<span className={`clientValue ${(() => {
											switch (supplierClient.status) {
												case "Active":
												case "Ready for review":
													return "success"

												case "Pre-authorized":
												case "Review requested":
													return "pending"

												case "New request":
													return "new"

												case "Rejected":
													return "failed"

												default:
													break;
											}
										})()}`}>
											{supplierClient.status}
										</span>
										<span className="clientTitle">Status</span>
										<span className="clientValue actions">
											{supplierClient.status === "Ready for review" || supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ?
												<>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="activeTooltip">
																Active
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Active")} />
													</OverlayTrigger>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="authorizeTooltip">
																Authorize
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Authorized")} />
													</OverlayTrigger>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="rejectTooltip">
																Reject
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Rejected")} />
													</OverlayTrigger>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="reviewTooltip">
																Request Review
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Review requested")} />
													</OverlayTrigger>
												</> :
												<React.Fragment>
													{"No actions required"}
													<button className="btnBgBlueTextGradient" onClick={(e) => navigate("/home/suppliers/supplierProfile/" + props.supplier._id)}>
														Profile</button>
												</React.Fragment>
											}
										</span>
										<span className="clientTitle">Action</span>
									</div>
								)

							case "Admin client":
								const clientEntitiesIDs = props.clients && props.clients.find(client => client._id.toString() === props.userLogged.dataID.toString()).entities.map(entity => entity._id.toString())
								return (
									clientEntitiesIDs && clientEntitiesIDs.includes(supplierClient.clientID) && <div key={index} className="clientRow">
										<span className="clientHeaderTitle">{props.clients.find(clients => clients.entities.find(entity => entity._id === supplierClient.clientID)).entities.find(entity => entity._id === supplierClient.clientID).entityName}</span>
										<span className={`clientValue ${(() => {
											switch (supplierClient.status) {
												case "Active":
												case "Ready for review":
													return "success"

												case "Pre-authorized":
												case "Review requested":
													return "pending"

												case "New request":
													return "new"

												case "Rejected":
													return "failed"

												default:
													break;
											}
										})()}`}>
											{supplierClient.status}
										</span>
										<span className="clientTitle">Status</span>
										<span className="clientValue actions">
											{supplierClient.status === "Ready for review" || supplierClient.status === "Pre-authorized" || supplierClient.status === "Review requested" ?
												<>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="activeTooltip">
																Active
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Active")} />
													</OverlayTrigger>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="authorizeTooltip">
																Authorize
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Authorized")} />
													</OverlayTrigger>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="rejectTooltip">
																Reject
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Rejected")} />
													</OverlayTrigger>
													<OverlayTrigger placement="top"
														overlay={
															<Tooltip id="reviewTooltip">
																Request Review
															</Tooltip>
														}
													>
														<div onClick={() => props.handleSaveInfo(props.supplier, supplierClient.clientID, "Review requested")} />
													</OverlayTrigger>
												</> :
												"No actions required"
											}
										</span>
										<span className="clientTitle">Action</span>
									</div>
								)

							default:
								break;
						}
					})}
				</div>

				<div className="commentActions">
					<button className="btnBgBlueTextGradient" onClick={props.onHide}>Close</button>
				</div>
			</Modal.Body>
		</Modal >
	)
}

// MODAL TO SHOW ALL THE SUPPLIER INDUSTRIES
function SupplierIndustriesModalReviewForAuth(props) {
	const { show, onHide, supplierIndustries } = props
	return (
		<Modal
			className="supplierIndustriesModalReviewForAuth"
			size="lg"
			centered
			show={show}
			onHide={onHide}
		>
			<Modal.Body>
				<h4 className="commentTitle">Industries</h4>

				<div className="industriesContainer">
					{supplierIndustries.map((industry, index) => (
						<span key={index} className="industry">{industry}</span>
					))}
				</div>

				<div className="actionButton">
					<button className="btnBgBlueTextGradient" onClick={onHide}>Close</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default function ReviewForAuth({ darkLightMode }) {
	// VARIABLES TO MANAGE THE USER LOGGED
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged
	const fromAlerts = useLocation()

	const [key, setKey] = useState(() => {
		const searchParams = new URLSearchParams(fromAlerts.search)
        const paramsExist = searchParams.get("fromAlerts")

		return paramsExist === "doc" ? "Document compliance" : 'Information Completed'
	});

	// VARIABLE TO SHOW THE CLIENTS MODAL
	const [showClientsModal, setShowClientsModal] = useState(() => {
		const searchParams = new URLSearchParams(fromAlerts.search)
        const paramsExist = searchParams.get("fromAlerts")

		
        return paramsExist === "review"
	})

	function updateModal() {
		const root = document.getElementById('root');
		const someChild = document.querySelector('.modal .modal-content');
		
		if (!someChild) return

		if (someChild && root.querySelector('.darkMode')) {
			 // Define a function to apply styles
			const applyStyle = (elements, styles) => {
				elements.forEach(ele => {
					Object.entries(styles).forEach(([key, value]) => {
						ele.style[key] = value;
					});
				});
			};

			// Apply styles to someChild
			applyStyle([someChild], {
				backgroundColor: '#131E29',
				webkitTextFillColor: '#FFFFFF'
			});

			// Define common styles for inputs and headers
			const commonInputStyle = {
				background: '#27343F'
			};

			// Apply styles to table header, inputs, and dropdowns
			applyStyle(document.querySelectorAll('.modal .modal-content th, .modal .modal-content .inputContainer > input, .modal .modal-content .inputContainer > textarea, .modal .modal-content .inputContainer .dropdown-toggle, .modal .modal-content .infoContainer > input'), commonInputStyle);

			// Apply styles to dropdown items
			applyStyle(document.querySelectorAll('.modal .modal-content .dropdown-item'), commonInputStyle);

			// Apply styles to actions
			applyStyle(document.querySelectorAll('.modal .modal-content .actions > div'), {
				backgroundColor: '#FFFFFF'
			});

			// Apply styles to action dropdowns
			applyStyle(document.querySelectorAll('.modal .modal-content .actions .dropdown .dropdown-toggle, .modal .modal-content .actions .dropdown .newAlone, .modal .modal-content .actions .dropdown .successAlone, .modal .modal-content .actions .dropdown .pendingAlone'), commonInputStyle);

			// Define text fill colors for action dropdowns
			const dropdownColors = {
				'.newAlone': '#00A3FF',
				'.successAlone': '#08D1A1',
				'.pendingAlone': '#FF7B31'
			};

			Object.entries(dropdownColors).forEach(([selector, color]) => {
				applyStyle(document.querySelectorAll(`.modal .modal-content .actions .dropdown ${selector}`), {
					webkitTextFillColor: color,
					backgroundColor: commonInputStyle.background
				});
			});
		}
	}

	useEffect(() => {
        if (showClientsModal) {
            const timer = setTimeout(() => {
                updateModal();
            }, 200); // Small timeout to wait for the next render

            return () => clearTimeout(timer); // Cleanup the timeout on unmount
        }
    }, [showClientsModal]);
	

	const cleanedUrl = fromAlerts.pathname.split("?")[0];
	window.history.replaceState(null, "", cleanedUrl);

	// VARIABLE TO SHOW THE INDUSTRIES MODAL
	const [showIndustriesModal, setShowIndustriesModal] = useState(false)

	// VARIABLE TO MANAGE SUPPLIER INFO
	const [supplier, setSupplier] = useState(null);

	// VARIABLE TO MANAGE CLIENTS
	const [clients, setClients] = useState(null)

	// VARIABLE TO MANAGE SERVER DATE
	const [serverDate, setServerDate] = useState(null);

	// VARIABLE TO MANAGE USERNAME
	const [username, setUsername] = useState(null)

	// VARIABLES TO CONTROL THE PERCERT COMPLETED FOR THE TABS
	const [informationCompliancePercent, setInformationCompliancePercent] = useState(0)
	const [documentCompliancePercent, setDocumentCompliancePercent] = useState(0)
	const [historicDocumentCompliancePercent, setHistoricDocumentCompliancePercent] = useState(0)

	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getServerDate();
		getSupplierByID(id);
		getUsernameByID(id);
		getAllClients()
	}, [id])

	useEffect(() => {
		const hasRepse = supplier?.informationCompliance?.generalInformation?.hasRepse

		supplier && setInformationCompliancePercent(checkInformationCompliance(supplier.informationCompliance))

		let contadorDocumentCompliance = 0
		let contadorTotalDocumentCompliance = 0

		let documentsForCheck = []
		supplier && supplier.documentCompliance.genericDocuments.general.length > 0 && supplier.documentCompliance.genericDocuments.general.filter(document => {
			const currentYearCondition = document.yearCreatedAt === serverDate.year

			if (hasRepse) {
				return currentYearCondition
			}

			const checkListOfDocuments = !removeDocumentNoRepse.includes(document.documentID?.toString())

			return currentYearCondition && checkListOfDocuments
		}).forEach(document => {
			documentsForCheck.push(document)
		})

		supplier && supplier.documentCompliance.genericDocuments.periodic.length > 0 && supplier.documentCompliance.genericDocuments.periodic.filter(document => {
			const currentYearCondition = document.yearCreatedAt === serverDate.year

			if (hasRepse) {
				return currentYearCondition
			}

			const checkListOfDocuments = !removeDocumentNoRepse.includes(document.documentID?.toString())

			return currentYearCondition && checkListOfDocuments
		}).forEach(document => {
			documentsForCheck.push(document)
		})

		supplier && Object.values(documentsForCheck).forEach(value => {
			return (
				value.compliant ? contadorDocumentCompliance++ : undefined,
				contadorTotalDocumentCompliance++
			)
		})

		const docPercentage = contadorTotalDocumentCompliance ? Math.round(contadorDocumentCompliance / contadorTotalDocumentCompliance * 100) : 0

		supplier && setDocumentCompliancePercent(docPercentage)

		// LOGIC TO GET THE HISTORIC DOC COMPLIANCE
		let contadorHistoricDocumentCompliance = 0
		let contadorHistoricTotalDocumentCompliance = 0

		let historicDocumentsForCheck = []
		supplier && supplier.documentCompliance.genericDocuments.general.length > 0 && supplier.documentCompliance.genericDocuments.general.filter(document => {
			const checkListOfDocuments = !removeDocumentNoRepse.includes(document.documentID?.toString())
			
			return hasRepse || checkListOfDocuments
		}).forEach(document => {
			historicDocumentsForCheck.push(document)
		})

		supplier && supplier.documentCompliance.genericDocuments.periodic.length > 0 && supplier.documentCompliance.genericDocuments.periodic.filter(document => {
			const checkListOfDocuments = !removeDocumentNoRepse.includes(document.documentID?.toString())
			
			return hasRepse || checkListOfDocuments
		}).forEach(document => {
			historicDocumentsForCheck.push(document)
		})

		supplier && Object.values(historicDocumentsForCheck).forEach(value => {
			return (
				value.compliant ? contadorHistoricDocumentCompliance++ : undefined,
				contadorHistoricTotalDocumentCompliance++
			)
		})

		const docHistoricPercentage = contadorHistoricTotalDocumentCompliance ? Math.round(contadorHistoricDocumentCompliance / contadorHistoricTotalDocumentCompliance * 100) : 0

		supplier && setHistoricDocumentCompliancePercent(docHistoricPercentage)
	}, [supplier])

	const handleSaveInfo = (updatedSupplier, clientID, status) => {
		return new Promise((resolve, _reject) => {
			Swal.fire({
				toast: true,
				footer: 'Loading, please wait',
				showConfirmButton: false,
				// allowOutsideClick: false,
				// allowEscapeKey: false,
				position: "bottom-end",
				didOpen: () => {
					Swal.showLoading()
				},
			})

			const formData = new FormData();
			const supplierToSave = updatedSupplier

			formData.append("rfc", supplierToSave.informationCompliance.generalInformation.rfc) //This is specific to recive the rfc to save files by carpets
			let saveWithFile = false
			const filesToSave = []

			if (clientID) {
				const clientToUpdate = supplierToSave.clients.find(client => client.clientID === clientID)

				clientToUpdate.statusHistory.push({
					prevStatus: clientToUpdate.status,
					dateStatusChanged: serverDate.date
				})

				clientToUpdate.status = status
				formData.append("sendEmail", true)
				formData.append("supplierClientID", JSON.stringify([clientID]))
				formData.append("rol", userLogged.rol)
				formData.append("dataID", userLogged.dataID)
			}

			supplierToSave.informationCompliance.generalInformation.cvp.agreements.forEach(agreement => {
				if (!agreement.yearCreatedAt || !agreement.monthCreatedAt || !agreement.dayCreatedAt) {
					agreement.yearCreatedAt = serverDate.year
					agreement.monthCreatedAt = serverDate.month
					agreement.dayCreatedAt = serverDate.day
				}

				if (agreement.document && agreement.document.name) {
					saveWithFile = true
					const fileToSave = agreement.document
					const fileNewName = `${agreement.yearCreatedAt}-${agreement.monthCreatedAt}-${agreement.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					agreement.checkThis = true
				}
				agreement.uploadedAt = agreement.uploadedAt ? agreement.document && agreement.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : agreement.document ? agreement.uploadedAt : "" : agreement.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
				agreement.document = agreement.document && (agreement.document.name ? `${agreement.yearCreatedAt}-${agreement.monthCreatedAt}-${agreement.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : agreement.document)
			})

			supplierToSave.documentCompliance.genericDocuments.general.forEach(document => {
				const docUploadAtValue = document.uploadedAt ? (
					(document.document && document.document.name) ?
						`${serverDate.month}/${serverDate.day}/${serverDate.year}` :
						document.document ?
							document.uploadedAt :
							"") :
					document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

				document.compliantPending = document.compliant ? false : true
				if (document.document && document.document.name) {
					saveWithFile = true
					const fileToSave = document.document
					const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					document.checkThis = true
				}
				document.uploadedAt = docUploadAtValue
				document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)

				if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
					document.yearCreatedAt = serverDate.year
					document.monthCreatedAt = serverDate.month
					document.dayCreatedAt = serverDate.day
				}
			});

			supplierToSave.documentCompliance.genericDocuments.periodic.forEach(document => {
				const docUploadAtValue = document.uploadedAt ? (
					(document.document && document.document.name) ?
						`${serverDate.month}/${serverDate.day}/${serverDate.year}` :
						document.document ?
							document.uploadedAt :
							"") :
					document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

				document.compliantPending = document.compliant ? false : true
				if (document.document && document.document.name) {
					saveWithFile = true
					const fileToSave = document.document
					const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					document.checkThis = true
				}
				document.uploadedAt = docUploadAtValue
				document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)

				if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
					document.yearCreatedAt = serverDate.year
					document.monthCreatedAt = serverDate.month
					document.dayCreatedAt = serverDate.day
				}
			});

			supplierToSave.documentCompliance.clients.forEach(client => {
				client.clientDocuments.general.forEach(document => {
					const docUploadAtValue = document.uploadedAt ? (
						(document.document && document.document.name) ?
							`${serverDate.month}/${serverDate.day}/${serverDate.year}` :
							document.document ?
								document.uploadedAt :
								"") :
						document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

					document.compliantPending = document.compliant ? false : true
					if (document.document && document.document.name) {
						saveWithFile = true
						const fileToSave = document.document
						const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
						filesToSave.push({
							file: fileToSave,
							fileNewName: fileNewName
						})
						document.checkThis = true
					}
					document.uploadedAt = docUploadAtValue
					document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)

					if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
						document.yearCreatedAt = serverDate.year
						document.monthCreatedAt = serverDate.month
						document.dayCreatedAt = serverDate.day
					}
				});

				client.clientDocuments.periodic.forEach(document => {
					const docUploadAtValue = document.uploadedAt ? (
						(document.document && document.document.name) ?
							`${serverDate.month}/${serverDate.day}/${serverDate.year}` :
							document.document ?
								document.uploadedAt :
								"") :
						document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

					document.compliantPending = document.compliant ? false : true
					if (document.document && document.document.name) {
						saveWithFile = true
						const fileToSave = document.document
						const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
						filesToSave.push({
							file: fileToSave,
							fileNewName: fileNewName
						})
						document.checkThis = true
					}
					document.uploadedAt = docUploadAtValue
					document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)

					if (!document.yearCreatedAt || !document.monthCreatedAt || !document.dayCreatedAt) {
						document.yearCreatedAt = serverDate.year
						document.monthCreatedAt = serverDate.month
						document.dayCreatedAt = serverDate.day
					}
				});
			});

			if (supplierToSave.informationCompliance.generalInformation.curriculum.document?.name) {
				saveWithFile = true
				const fileToSave = supplierToSave.informationCompliance.generalInformation.curriculum.document
				const fileNewName = `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				supplierToSave.informationCompliance.generalInformation.curriculum.checkThis = true
			}
			supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt = supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt ? supplierToSave.informationCompliance.generalInformation.curriculum.document && supplierToSave.informationCompliance.generalInformation.curriculum.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : supplierToSave.informationCompliance.generalInformation.curriculum.document ? supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt : "" : supplierToSave.informationCompliance.generalInformation.curriculum.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
			supplierToSave.informationCompliance.generalInformation.curriculum.document = supplierToSave.informationCompliance.generalInformation.curriculum.document && (supplierToSave.informationCompliance.generalInformation.curriculum.document.name ? `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : supplierToSave.informationCompliance.generalInformation.curriculum.document)

			supplierToSave?.staffs?.forEach(staff => {
				if (staff.profilePhoto?.document && staff.profilePhoto?.document.name) {
					saveWithFile = true
					const fileToSave = staff.profilePhoto.document
					const fileNewName = `${staff.profilePhoto.yearCreatedAt}-${staff.profilePhoto.monthCreatedAt}-${staff.profilePhoto.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					staff.profilePhoto.checkThis = true
				}
				if (staff.profilePhoto) {
					staff.profilePhoto.uploadedAt = staff.profilePhoto.uploadedAt ? staff.profilePhoto.document && staff.profilePhoto.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : staff.profilePhoto.document ? staff.profilePhoto.uploadedAt : "" : staff.profilePhoto.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
					staff.profilePhoto.document = staff.profilePhoto.document && (staff.profilePhoto.document.name ? `uploads/${supplierToSave.informationCompliance.generalInformation.rfc}/${staff.profilePhoto.yearCreatedAt}-${staff.profilePhoto.monthCreatedAt}-${staff.profilePhoto.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : staff.profilePhoto.document)
				}
			})

			supplierToSave.clients.forEach(client => {
				client.contracts.forEach(contract => {
					if (contract.contract.document && contract.contract.document.name) {
						saveWithFile = true
						const fileToSave = contract.contract.document
						const fileNewName = `${serverDate.year}-${serverDate.month}-${contract.contract.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
						filesToSave.push({
							file: fileToSave,
							fileNewName: fileNewName
						})
						contract.contract.checkThis = true
					}
					contract.contract.uploadedAt = contract.contract.uploadedAt ? contract.contract.document && contract.contract.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : contract.contract.document ? contract.contract.uploadedAt : "" : contract.contract.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
					contract.contract.document = contract.contract.document && (contract.contract.document.name ? `${serverDate.year}-${serverDate.month}-${contract.contract.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : contract.contract.document)
				});
			})

			supplierToSave.productsAndServices.products.forEach(product => {
				if (product.drawing.document && product.drawing.document.name) {
					saveWithFile = true
					const fileToSave = product.drawing.document
					const fileNewName = `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					product.drawing.checkThis = true
				}
				product.drawing.uploadedAt = product.drawing.uploadedAt ? product.drawing.document && product.drawing.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : product.drawing.document ? product.drawing.uploadedAt : "" : product.drawing.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
				product.drawing.document = product.drawing.document && (product.drawing.document.name ? `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : product.drawing.document)

				if (product.picture.document && product.picture.document.name) {
					saveWithFile = true
					const fileToSave = product.picture.document
					const fileNewName = `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					product.picture.checkThis = true
				}
				product.picture.uploadedAt = product.picture.uploadedAt ? product.picture.document && product.picture.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : product.picture.document ? product.picture.uploadedAt : "" : product.picture.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
				product.picture.document = product.picture.document && (product.picture.document.name ? `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : product.picture.document)

			})

			saveWithFile && filesToSave.forEach(doc => {
				formData.append("document", doc.file, doc.fileNewName)
			})
			formData.append("saveWithFile", saveWithFile)
			formData.append("supplier", JSON.stringify(supplierToSave))

			axios.put(URI_SUPPLIER + id, formData).then(response => {
				setSupplier(response.data.supplier)
				Swal.fire({
					toast: true,
					icon: 'success',
					text: 'Supplier updated successfully',
					showConfirmButton: false,
					timer: 2000,
					timerProgressBar: true,
					position: "bottom-end"
				})
				resolve(response.data.supplier)
				if (status === "Active" || status === "Authorized" || status === "Rejected" || status === "Review requested") {
					navigate(-1)
				}
			}).catch(err => {
				Swal.fire({
					toast: true,
					icon: 'error',
					text: 'Failed to update supplier',
					showConfirmButton: false,
					timer: 3000,
					timerProgressBar: true,
					position: "bottom-end"
				})
				console.log("Error al autorizar el supplier: " + err)
				resolve(false)
			})
		})
	}

	const getServerDate = () => {
		axios.get(URI_SERVER_DATE).then(response => {
			setServerDate(response.data)
		}).catch(err => {
			console.log("Error al obtener la fecha del servidor" + err)
		})
	}

	const getSupplierByID = (id) => {
		axios.get(URI_SUPPLIER + id).then(response => {
			setSupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener el supplier por id:" + err)
		})
	}

	const getUsernameByID = (id) => {
		axios.post(URI_USERS, { idsToFind: [id] }).then(response => {
			setUsername(response.data[0].username)
		}).catch(err => {
			console.log("Error al obtener el supplier por id:" + err)
		})
	}

	// FUNCTION TO GET ALL CLIENTS
	const getAllClients = () => {
		axios.get(URI_CLIENT).then(response => {
			setClients(response.data)
		}).catch(err => {
			console.log("Error al obtener los clientes")
		})
	}

	// SEND NEW COMMENTS NOTIFICATION TO SUPPLIER
	const sendNotification = (email) => {
		const data = {
			email
		}

		Swal.fire({
			title: "Send email to supplier?",
			icon: "?",
			// showCancelButton: true,
			confirmButtonText: "Send email"
		}).then((result) => {
			if (result.isConfirmed) {
				axios.post(URI_NOTIFICATION, data).then(res => {
					Swal.fire({
						toast: true,
						icon: 'success',
						text: 'Email sent',
						showConfirmButton: false,
						timer: 2000,
						timerProgressBar: true,
						position: "bottom-end"
					})
				}).catch(err => {
					console.log("Error al enviar correo")
				})
			}
		});

	}

	return (
		<section className={`reviewForAuth ${darkLightMode ? "darkMode" : null}`}>
			<div className="header">
				<div className="generalInformation">
					<div className="headInformation">
						<img className="goBack" src={back} alt="Prodensa Supplier" onClick={() => navigate(-1)} />
						<img className="profilePhoto" src={
							supplier && supplier.informationCompliance.generalInformation.profilePhoto.document && !supplier.informationCompliance.generalInformation.profilePhoto.document.name
								? process.env.REACT_APP_URI_UPLOADS + supplier.informationCompliance.generalInformation.rfc + "/" + supplier.informationCompliance.generalInformation.profilePhoto.document
								: profileExample
						} alt="Prodensa Supplier" />
						<h3>
							{supplier && supplier.informationCompliance.generalInformation.companyName}
							<span>All time document compliant: {historicDocumentCompliancePercent}%</span>
						</h3>
						<h4 className="ratingValue">
							{supplier && supplier.rating ? supplier.rating.$numberDecimal : "Unrated"}
							{supplier && supplier.rating && supplier.rating.$numberDecimal ? <img className="ratingImg" src={starRating} alt="Prodensa Supplier" /> : undefined}
						</h4>
					</div>
					<div className="industriesInformation">
						<div className="industriesContainer">
							{supplier && supplier.informationCompliance.facilitiesCapabilities.categories.map((category, index) => (
								index < 4 && <span key={index}>{category}</span>
							))}
						</div>
						{supplier && supplier.informationCompliance.facilitiesCapabilities.categories.length > 3 && <button className="btnBgBlueTextGradient" onClick={() => setShowIndustriesModal(true)}>
							See all ({supplier && supplier.informationCompliance.facilitiesCapabilities.categories.length})
						</button>}
					</div>
					<p className="description">{supplier && supplier.informationCompliance.facilitiesCapabilities.description}</p>
					{userLogged.rol === "Client" && <div className="actions">
						<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Active") }}>Active supplier</button>
						<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Authorized") }}>Authorize supplier</button>
						<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Rejected") }}>Reject supplier</button>
						<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Review requested") }}>Request review</button>
					</div>}
					{(userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client") && <button className="btnBgBlueTextGradient" onClick={() => setShowClientsModal(true)}>
						View actions for clients
					</button>}
				</div>

				<div className="contactInformation">
					{supplier && supplier.informationCompliance.generalInformation.cvp.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Supplier" />}
					<div className="info">
						<span className="infoTitle">Address</span>
						<span className="infoText">
							{supplier && supplier.informationCompliance.generalInformation.address.streetAndNumber}<br />
							C.P. {supplier && supplier.informationCompliance.generalInformation.address.zipCode}<br />
							{supplier && supplier.informationCompliance.generalInformation.address.city + " " + supplier.informationCompliance.generalInformation.address.state}

						</span>
					</div>
					<div className="info">
						<span className="infoTitle">Contact information</span>
						<span className="infoText">
							{supplier && supplier.informationCompliance.generalInformation.contactInformation?.name}<br />
							{supplier && supplier.informationCompliance.generalInformation.contactInformation?.email}<br />
							{supplier && supplier.informationCompliance.generalInformation.contactInformation?.phone}
						</span>
					</div>
					<div className="info">
						<span className="infoTitle">Website</span>
						<span className="infoText">{supplier && supplier.informationCompliance.generalInformation.website}</span>
					</div>
				</div>
				{userLogged.rol === "Client" && <div className="actions d-lg-none">
					<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Active") }}>Active supplier</button>
					<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Authorized") }}>Authorize supplier</button>
					<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Rejected") }}>Reject supplier</button>
					<button className="btnBgBlueTextGradient" onClick={() => { handleSaveInfo(supplier, userLogged.dataID, "Review requested") }}>Request review</button>
				</div>}
				{(userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client") && <button className="btnBgBlueTextGradient d-lg-none" onClick={() => setShowClientsModal(true)}>
					View actions for clients
				</button>}
			</div>

			<div className="tabs-container">
				<Tabs
					id="controlled-tab-example"
					activeKey={key}
					onSelect={(k) => setKey(k)}
				>
					<Tab eventKey="Information Completed" title={`1 Information Completed (${informationCompliancePercent}%)`}>
						{supplier && serverDate && <InformationCompliance
							supplier={supplier}
							informationCompliance={supplier.informationCompliance}
							setSupplier={setSupplier}
							username={username}
							serverDate={serverDate}
							handleSaveChanges={handleSaveInfo}
						/>}
					</Tab>
					<Tab eventKey="Document compliance" title={`2 Document compliance (${documentCompliancePercent}%)`}>
						{supplier && <DocumentComplianceTable
							supplier={supplier}
							serverDate={serverDate}
							handleSaveChanges={handleSaveInfo}
							userLogged={userLogged}
							darkLightMode={darkLightMode}
						/>}
					</Tab>
					{supplier?.informationCompliance?.generalInformation?.hasRepse && (
						<Tab eventKey="staff" title="3 Staff">
							{supplier && <StaffList
								supplier={supplier}
								rfc={supplier.informationCompliance.generalInformation.rfc}
								handleSaveChanges={handleSaveInfo}
							/>}
						</Tab>
					)}
					<Tab eventKey="Quality Survey" title="4 Quality Survey">
						{supplier && <QualitySurvey
							qualitySurvey={supplier.qualitySurvey}
							setSupplier={setSupplier}
							rfc={supplier.informationCompliance.generalInformation.rfc}
						/>}
					</Tab>
					<Tab eventKey="Products/Services" title="5 Products/Services">
						{supplier && serverDate && <ProductsAndServices
							supplier={supplier}
							productsAndServices={supplier.productsAndServices}
							setSupplier={setSupplier}
							serverDate={serverDate}
							userLogged={userLogged}
							rfc={supplier.informationCompliance.generalInformation.rfc}
							handleSaveChanges={handleSaveInfo}
						/>}
					</Tab>
					<Tab eventKey="contracts" title="6 Contracts">
						{supplier && <Contracts
							supplier={supplier}
							clients={supplier.clients}
							setSupplier={setSupplier}
							serverDate={serverDate}
							userLogged={userLogged}
							rfc={supplier.informationCompliance.generalInformation.rfc}
							handleSaveChanges={handleSaveInfo}
						/>}
					</Tab>
				</Tabs>

				<OverlayTrigger placement="top"
					overlay={<Tooltip id="send-email-tooltip">
						Send email to supplier
					</Tooltip>}>
					<button className="btnBgBlueTextGradient send-email"
						onClick={() => sendNotification(supplier.informationCompliance.generalInformation.contactInformation.email)}
					>
						Send comments
					</button>
				</OverlayTrigger>
			</div>

			{key !== "Document compliance" && <div className="actionButtons">
				<button className="btnBgBlueTextGradient" onClick={() => navigate(-1)}>Discard changes</button>
				<button className="btnBgBlueTextGradient" onClick={() => handleSaveInfo(supplier)}>Save changes</button>
			</div>}

			<button className="saveChanges" onClick={() => handleSaveInfo(supplier)}>
				<img src={save} alt="Prodensa Supplier" />
			</button>
			{supplier && clients && <SupplierClientsModalReviewForAuth
				supplier={supplier}
				supplierClients={supplier.clients}
				clients={clients}
				show={showClientsModal}
				onHide={() => setShowClientsModal(false)}
				handleSaveInfo={handleSaveInfo}
				userLogged={userLogged}
			/>}

			{supplier && <SupplierIndustriesModalReviewForAuth
				supplierIndustries={supplier.informationCompliance.facilitiesCapabilities.categories}
				show={showIndustriesModal}
				onHide={() => setShowIndustriesModal(false)}
			/>}
		</section>
	)
}