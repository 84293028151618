import "../../assets/css/tabs/contracts.css"

import close from "../../assets/images/close.svg"
import deleteIcon from "../../assets/images/delete.svg"

import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import axios from "axios"
import { useCookies } from "react-cookie"
import { formatCommas } from "../../utilities/formatCommas"
import Swal from "sweetalert2"

const URI_CLIENT = process.env.REACT_APP_URI_CLIENT

export default function Contracts(props) {
    // VARIABLE TO MANAGE SUPPLIER CLIENTS
    const clientsCopy = props.clients

    // VARIABLES TO MANAGE THE USER LOGGED
    const [cookie] = useCookies(["userLogged"])
    const userLogged = cookie.userLogged

    // VARIABLE TO MANAGE ALL THE CLIENTS
    const [allClients, setAllClients] = useState(null)

    // VARIABLE TO MANAGE THE ENTITIES TO VIEW IN THE TABLE BY THE USER LOGGED
    const [entitiesToView, setEntitiesToView] = useState(null)

    // VARIABLE TO MANAGE THE CURRENT ENTITY TO SHOW
    const [entity, setEntity] = useState(null)

    useEffect(() => {
        getAllClients()
    }, [])

    useEffect(() => {
        if (allClients) {
            switch (userLogged.rol) {
                case "Master":
                case "Super admin":
                    setEntitiesToView(clientsCopy.map(entitySuplier => {
                        return {
                            _id: entitySuplier.clientID,
                            entityName: allClients.find(client => client.entities.some(entity => entity._id === entitySuplier.clientID)).entities.find(entity => entity._id === entitySuplier.clientID).entityName
                        }
                    }))
                    break;

                case "Admin client":
                    const entitiesIDs = allClients.find(client => client._id === userLogged.dataID).entities.map(entity => entity._id)

                    setEntitiesToView(clientsCopy.filter(entitySuplier => entitiesIDs.includes(entitySuplier.clientID)).map(entitySuplier => {
                        return {
                            _id: entitySuplier.clientID,
                            entityName: allClients.find(client => client.entities.some(entity => entity._id === entitySuplier.clientID)).entities.find(entity => entity._id === entitySuplier.clientID).entityName
                        }
                    }))
                    break;

                case "Client":
                    setEntitiesToView([
                        {
                            _id: userLogged.dataID,
                            entityName: allClients.find(client => client.entities.some(entity => entity._id === userLogged.dataID)).entities.find(entity => entity._id === userLogged.dataID).entityName
                        }
                    ])

                    break;


                default:
                    break;
            }
        }
    }, [allClients])

    // FUNCTION TO GET ALL THE CLIENTS
    const getAllClients = () => {
        axios.get(URI_CLIENT).then(response => {
            setAllClients(response.data)
        }).catch(err => {
            console.log("Error al obtener todos los clients: " + err)
        })
    }

    // FUNCTION TO ADD NEW CONTRACT FOR A CLIENT
    const handleAddContract = () => {
        clientsCopy.find(client => client.clientID === entity._id).contracts.push({
            contractName: "",
            contractStatus: "Active",
            contractExpedition: "",
            contractExpire: "",
            contractAmount: 0,
            contract: {
                document: "",
                uploadedAt: "",
                yearCreatedAt: props.serverDate.year,
                monthCreatedAt: props.serverDate.month,
                dayCreatedAt: props.serverDate.day,
            },
        })

        props.setSupplier(prevState => {
            return {
                ...prevState,
                clients: clientsCopy
            }
        })
    }

    // FUNCTION TO DELETE CONTRACT FOR A CLIENT
    const handleDeleteContract = (position) => {
        clientsCopy.find(client => client.clientID === entity._id).contracts.splice(position, 1)

        props.setSupplier(prevState => {
            return {
                ...prevState,
                clients: clientsCopy
            }
        })
    }

    // FUNCTION TO CHANGE CONTRACT FOR A CLIENT
    const handleChangeContract = (position, property, value) => {
        if (property === "contract") {
            // In this case value have the element target
            if (value && value.files[0]) {
                if (value.files[0].type === "application/pdf") {
                    clientsCopy.find(client => client.clientID === entity._id).contracts[position][property].document = value.files[0]
                } else {
                    value.value = null
                    clientsCopy.find(client => client.clientID === entity._id).contracts[position][property].document = null

                    Swal.fire({
                        icon: 'error',
                        text: 'The file is not a PDF.',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }
            } else {
                clientsCopy.find(client => client.clientID === entity._id).contracts[position][property].document = null
            }
            
            const supplierToUpdate = {
                ...props.supplier,
                clients: clientsCopy
            }

            props.handleSaveChanges(supplierToUpdate)
        } else {
            clientsCopy.find(client => client.clientID === entity._id).contracts[position][property] = value
        }

        props.setSupplier(prevState => {
            return {
                ...prevState,
                clients: clientsCopy
            }
        })
    }
    
    return (
        <section className="contracts">
            <div className="inputSections">
                <div className="titleAndClient">
                    <h5 className="title">
                        Contracts for client:
                        <Dropdown className="clientDropdown">
                            <Dropdown.Toggle className={`${!entity ? "default" : ""}`}>
                                {entity ? entity.entityName : "Select a client"}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {entitiesToView?.map((entity, index) => (
                                    <Dropdown.Item key={index} onClick={() => setEntity(entity)}>{entity.entityName}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </h5>
                </div>
                {entity && clientsCopy.find(client => client.clientID === entity._id).contracts.map((contract, contractIndex) => (
                    <div key={contractIndex} className="contractInformation">
                        <div className="inputContainer">
                            <span className="label">Contract name</span>
                            <input type="text" value={contract.contractName} onChange={(e) => handleChangeContract(contractIndex, "contractName", e.target.value)} />
                            <img className="deleteIcon d-lg-none" src={deleteIcon} onClick={() => handleDeleteContract(contractIndex)} alt="Prodensa Supplier" />
                        </div>
                        <div className="inputContainer calendar">
                            <span className="label">Expedition date</span>
                            <input type="date" value={contract.contractExpedition} onChange={(e) => handleChangeContract(contractIndex, "contractExpedition", e.target.value)} />
                        </div>
                        <div className="inputContainer calendar">
                            <span className="label">Expire date</span>
                            <input type="date" value={contract.contractExpire} onChange={(e) => handleChangeContract(contractIndex, "contractExpire", e.target.value)} />
                        </div>
                        <div className="inputContainer dollars">
                            <span className="label">Contract value</span>
                            <input type="text" value={formatCommas(contract.contractAmount).formattedValue} onChange={(e) => handleChangeContract(contractIndex, "contractAmount", formatCommas(e.target.value).numericValue)} />
                        </div>
                        <div className="inputContainer">
                            <span className="label">Contract document</span>
                            <div className="fileContainer">
                                {contract.contract.document
                                    ? <React.Fragment>
                                        <a className="downloadFile btnBgBlueTextGradient"
                                            href={process.env.REACT_APP_URI_UPLOADS +
                                                props.rfc + "/" +
                                                (contract.contract.document.name ?? contract.contract.document)}
                                            target="_blank">View attached file</a>
                                        <span className="fileName">{contract.contract.document.name ?? contract.contract.document}</span>
                                        <img className="close" src={close} alt="Prodensa Supplier"
                                            onClick={() => handleChangeContract(contractIndex, "contract", "")} />
                                    </React.Fragment>
                                    : <input type="file" accept=".pdf"
                                        onChange={(e) => handleChangeContract(contractIndex, "contract", e.target)}
                                    />}
                            </div>
                        </div>
                        <div className="inputContainer d-none d-lg-flex">
                            <img className="deleteIcon" src={deleteIcon} onClick={() => handleDeleteContract(contractIndex)} alt="Prodensa Supplier" />
                        </div>
                    </div>
                ))}
            </div>

            {entity && <button className="btnBgBlueTextGradient" onClick={() => handleAddContract()}>Agregar contrato</button>}
        </section>
    )
}