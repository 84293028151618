import addProfilePhoto from "../../../assets/images/uploadPicture.svg"
import close from "../../../assets/images/close.svg"

import { Modal } from "react-bootstrap";
import { useRef } from "react";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";

export default function ViewStaff(props) {
	// VARIABLES RECIEVED FROM PROPS
	const { showViewModal, onHide, staffToShow, rfc, supplierId,  companyName, handleChangeProfilePhoto, handleDeleteStaff } = props

	// REFERENCE TO CONTROL THE INPUT FILE FOR THE STAFF PROFILE PHOTO
	const staffPhotoRef = useRef(null)

	// FUNCTION TO CONTROL THE FILE EXPLORER
	const handleOpenFileExplorer = () => {
		if (!staffPhotoRef.current)
			return

		staffPhotoRef.current.click()
	}

	// FUNCTION TO SAVE THE STAFF PROFILE PHOTO
	const handleInputPhotoChange = () => {
		const allowedImageTypes = ["image/jpeg", "image/png", "image/webp"];

		if (allowedImageTypes.includes(staffPhotoRef.current.files[0].type)) {
			handleChangeProfilePhoto(staffPhotoRef.current.files[0])
			onHide()
		} else {
			staffPhotoRef.current.value = null
			Swal.fire({
				icon: 'error',
				text: 'The file is not a image format (jpeg, png, webp).',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		}
	}

	// FUNCTION TO DOWNLOAD THE QR CODE TO IMAGE (SVG)
	const downloadQR = (idQr) => {
		const qrCode = document.getElementById(idQr);

		const svgString = new XMLSerializer().serializeToString(qrCode)

		const blob = new Blob([svgString], { type: 'image/svg+xml' });
		const url = URL.createObjectURL(blob);

		const a = document.createElement('a');
		a.href = url;
		a.download = 'qrCode.svg';
		document.body.appendChild(a);
		a.click();

		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	return (
		<Modal
			className="viewStaffModal"
			show={showViewModal}
			onHide={onHide}
			size="lg"
			centered>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => onHide()} alt="Prodensa Supplier" />

				<QRCode id={staffToShow._id} className="qrCode" bgColor="transparent" size={256} viewBox="0 0 256 256"
					value={`{supplierId: ${supplierId}, staffId: ${staffToShow._id}}`} />

				<div className="staffResume">
					<input ref={staffPhotoRef} type="file" className="d-none" onChange={handleInputPhotoChange} />
					<img className="profilePhoto" onClick={handleOpenFileExplorer}
						src={staffToShow.profilePhoto?.document
							? process.env.REACT_APP_URI_LOCALHOST + staffToShow.profilePhoto.document
							: addProfilePhoto}
						alt="Prodensa Supplier"
					/>
					<div className="information">
						<span className="textInfo">Employee information</span>
						<span className="textTitle">{staffToShow.name}</span>
						<span className="textInfo">Name</span>
						<span className="textValue">{staffToShow.imss}</span>
						<span className="textInfo">IMSS</span>
						<span className="textValue">{staffToShow.curp}</span>
						<span className="textInfo">RFC/CURP</span>
						<span className="textValue">{companyName}</span>
						<span className="textInfo">Company name</span>
					</div>
				</div>

				<span className="downloadQR">Download employee QR Code <label>(SVG format)</label></span>
				<button className="btnBgBlueTextGradient" onClick={() => downloadQR(staffToShow._id)}>Download QR Code</button>
				<button className="btnBgWhiteTextBlack d-lg-none mt-2" onClick={() => { handleDeleteStaff(); props.onHide() }}>Delete</button>
			</Modal.Body>
		</Modal>
	)
}