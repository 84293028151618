export const HEADER_TITLES = [
    {
        label: "Generic",
        property: "name",
    },
    {
        label: "Location",
        property: "locationName",
    },
    {
        label: "Specific",
        property: "productName",
    },
    {
        label: "Warehouse",
        property: "warehouseName",
    },
]

export function getFilterProducts(items, value) {
    return items.filter(i => i.name.toUpperCase().includes(value.toUpperCase()))
}
