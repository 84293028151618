import "../../assets/css/industries/industries.css"

import sortIcon from "../../assets/images/sort.svg"
import moreDots from "../../assets/images/moreDots.svg"
import next from "../../assets/images/arrowForward.svg"
import close from "../../assets/images/close.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Modal } from "react-bootstrap"
import Swal from "sweetalert2"
import { useCookies } from "react-cookie"

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS
const URI_SUPPLIER_BY_ADMIN_CLIENT = process.env.REACT_APP_URI_SUPPLIERS_BY_ADMIN_CLIENT
const URI_INDUSTRY = process.env.REACT_APP_URI_INDUSTRY

function AddIndustryModal(props) {
	const [industryName, setIndustryName] = useState("")

	const saveIndustry = () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		axios.post(URI_INDUSTRY, {
			industryName: industryName
		}).then(respone => {
			Swal.fire({
				icon: 'success',
				text: 'Information saved successfully',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			props.onHide()
			props.getAllIndustries()
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				text: 'Failed to save information',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			console.log("Error al guardar la informacion de la industria: " + err)
		})
	}

	return (
		<Modal
			className="industryModal"
			show={props.show}
			onHide={props.onHide}
			size="md"
			centered
		>
			<Modal.Body>
				<img className="closeModal" src={close} onClick={() => props.onHide()} alt="Prodensa Supplier" />

				<div className="inputContainer">
					<span className="label">Name</span>
					<input type="text" onChange={(e) => setIndustryName(e.target.value)} />
				</div>

				<div className="actions">
					<button className="btnBgBlueTextGradient" onClick={() => saveIndustry()}>Add Industry</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default function Industries({ darkLightMode }) {
	// VARIABLE TO KNOW WHO IS LOGGED
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged

	// VARIABLE TO CONTROL DATA FROM TABLE
	const [dataTable, setDataTable] = useState([])
	const [dataTableCopy, setDataTableCopy] = useState([])

	const [currentPage, setCurrentPage] = useState(1)

	const totalPages = Math.ceil(dataTableCopy.length / 8);
	const indexOfLastItem = currentPage * 8;
	const indexOfFirstItem = indexOfLastItem - 8;
	const currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARIABLE TO CONTROL THE SUPPLIERS FILTERED BY CATEGORIES
	const [suppliers, setSuppliers] = useState(null)

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	// VARIABLE TO CONTROL DE ADD INDUSTRY MODAL
	const [modalShow, setModalShow] = useState(false);

	// VARIABLE TO NAVIGATE TO OTHER COMPONENT
	const navigate = useNavigate();

	const mainStyle = {
		background: darkLightMode ? "#131E29" : "white",
		color: darkLightMode ? "white" : "inherit"
	}

	// useEffect to add body listener
	useEffect(() => {
		getAllIndustries()
		userLogged.rol === "Master" || userLogged.rol === "Super admin" ? getAllSuppliers() : getAllSuppliersByClient()

		document.body.addEventListener("click", closeMenusFilters)

		return function cleanUp() {
			document.body.removeEventListener("click", closeMenusFilters)
		}
	}, [])

	// VARIABLES TO CONTROL MENU SHOW AND HIDE
	const [showSortMenu, setShowSortMenu] = useState(false)

	// VARIABLE TO MANAGE REF FROM MENUS
	const menuOptionsRef = useRef(null)
	const sortMenuRef = useRef(null)

	// FUNCTION TO CLOSE MENU FILTERS
	const closeMenusFilters = (e) => {
		let menusMoreOptions;

		if (menuOptionsRef.current && menuOptionsRef.current.contains(e.target)) {
			menusMoreOptions = Array.prototype.slice.call(document.getElementsByClassName("industryOptions"))
			menusMoreOptions.map(element => {
				if (!menuOptionsRef.current.nextElementSibling.contains(element)) {
					element.classList.remove("show")
				}
			})

			menuOptionsRef.current.nextElementSibling.classList.toggle("show")
		} else {
			menusMoreOptions = Array.prototype.slice.call(document.getElementsByClassName("industryOptions"))
			menusMoreOptions.map(element => {
				element.classList.remove("show")
			})
		}

		if (!sortMenuRef.current.contains(e.target)) {
			setShowSortMenu(false)
		}
	}

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		switch (sortBy) {
			case "Industry":
				setDataTableCopy(dataTable.sort((a, b) => {
					let fa = a.name.toLocaleLowerCase();
					let fb = b.name.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Quantity":
				setDataTableCopy(dataTable.sort((a, b) => {
					if (!order) {
						return suppliers && suppliers.filter(supplier => supplier.informationCompliance.facilitiesCapabilities.categories.includes(a.name.toUpperCase())).length - suppliers.filter(supplier => supplier.informationCompliance.facilitiesCapabilities.categories.includes(b.name.toUpperCase())).length
					} else {
						return suppliers && suppliers.filter(supplier => supplier.informationCompliance.facilitiesCapabilities.categories.includes(b.name.toUpperCase())).length - suppliers.filter(supplier => supplier.informationCompliance.facilitiesCapabilities.categories.includes(a.name.toUpperCase())).length
					}
				}))
				setOrder(!order)
				break

			default:
				break;
		}
	}

	// FUNCTION TO GET ALL CLIENTS
	const getAllIndustries = () => {
		axios.get(URI_INDUSTRY).then(response => {
			setDataTable(response.data)
			setDataTableCopy(response.data)
		}).catch(err => {
			console.log("Error al obtener las industrias: " + err)
		})
	}

	// FUNCTION TO GET ALL CLIENTS
	const getAllSuppliers = () => {
		axios.get(URI_SUPPLIER).then(response => {
			setSuppliers(response.data)
		}).catch(err => {
			console.log("Error al obtener los suppliers: " + err)
		})
	}

	// FUNCTION TO GET ALL CLIENTS FILTERED BY CLIENT
	const getAllSuppliersByClient = () => {
		axios.get(URI_SUPPLIER_BY_ADMIN_CLIENT + userLogged.dataID).then(response => {
			setSuppliers(response.data)
		}).catch(err => {
			console.log("Error al obtener los suppliers por client ID: " + err)
		})
	}

	// FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY INPUT SEARCH
	const searchFilter = (value) => {
		setCurrentPage(1)
		let dataFiltered = []
		dataFiltered = dataTable.filter(prevState => {
			if (prevState.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
				return prevState
			}

		})

		setDataTableCopy(dataFiltered)
	}

	return (
		<section className={`industry ${darkLightMode ? "darkMode" : null}`}>
			<div className="titleAndSearch">
				<h3 className="title">Industry configuration</h3>
				<div className="searchAndSort">
					<div className={`searchContainer ${darkLightMode ? "darkMode" : ""}`}>
						<input id="generalSearch" type="text" placeholder="Search by industry..."
							onChange={(e) => searchFilter(e.target.value)} />
					</div>
					<button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
					<div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
						<h5 className="title">Sort</h5>
						<span onClick={() => { sortTable(false, "Industry") }}>Industry (ascending)</span>
						<span onClick={() => { sortTable(true, "Industry") }}>Industry (descending)</span>
						<span onClick={() => { sortTable(false, "Quantity") }}>Quantity (ascending)</span>
						<span onClick={() => { sortTable(true, "Quantity") }}>Quantity (descending)</span>
					</div>
				</div>
			</div>

			<table className="tableIndustry">
				<thead>
					<tr>
						<th style={mainStyle}>
							<span>Industry</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
						</th>
						<th style={mainStyle}>
							<span>Qty. Suppliers</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Quantity")} />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map(industry => (
						<tr key={industry._id}>
							<td>
								<div className="infoContainer">
									<span onClick={() => navigate("./" + industry._id)}>{industry.name}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{suppliers && suppliers.filter(supplier => {
										return supplier.informationCompliance.facilitiesCapabilities.categories.includes(industry.name.toUpperCase()) && supplier.clients.some(client => client.status === "Active" || client.status === "Authorized")
									}).length}</span>
									<img ref={menuOptionsRef} className="moreOptions" src={moreDots} onClick={(e) => menuOptionsRef.current = e.target} alt="Prodensa Supplier" />
									<div className={`industryOptions ${darkLightMode ? "darkMode" : null}`}>
										<span onClick={() => navigate("/home/suppliers/?viewIndustry=" + industry.name.toUpperCase())}>View suppliers</span>
										<span onClick={() => navigate("./" + industry._id)}>Edit</span>
									</div>
								</div>
							</td>

						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none industryInformation">
				{currentItems.map(data => (
					<div key={data._id} className="industryRow">
						<h5 className="industryName">
							{data.name}
						</h5>
						<span className="industryTitle">Industry</span>
						<span className="industryValue">{suppliers && suppliers.filter(supplier => {
							return supplier.informationCompliance.facilitiesCapabilities.categories.includes(data.name.toUpperCase()) && supplier.clients.some(client => client.status === "Active" || client.status === "Authorized")
						}).length}</span>
						<span className="industryTitle">Qty. suppliers</span>
						<img ref={menuOptionsRef} className="moreOptions" src={moreDots} onClick={(e) => menuOptionsRef.current = e.target} alt="Prodensa Supplier" />
						<div className={`industryOptions ${darkLightMode ? "darkMode" : null}`}>
							<span onClick={() => navigate("/home/suppliers/?viewIndustry=" + data.name.toUpperCase())}>View suppliers</span>
							<span onClick={() => navigate("./" + data._id)}>Edit</span>
						</div>
					</div>
				))}
			</div>

			<div className="addAndPagination">
				<button className={`btnBgBlueTextGradient ${userLogged.rol === "Admin client" && "hideElement"}`} onClick={() => setModalShow(true)}>Add industry</button>
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => currentPage < totalPages ? setCurrentPage(currentPage + 1) : ""} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 7 ? currentItems.length : 8 * currentPage} of {dataTable.length}</span>

			<AddIndustryModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				getAllIndustries={getAllIndustries}
			/>
		</section>
	)
}