import "../../assets/css/requests/newRequest.css"
import close from "../../assets/images/close.svg"
import countryInformation from "../../assets/jsonData/countryInformation.json"

import { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Swal from 'sweetalert2'
import axios from "axios";
import currencyFormat from "../../utilities/currencyFormat";
import { addAction, deleteAllActionsBySupplierId } from "../../utilities/utils";

const URI = process.env.REACT_APP_URI_SUPPLIERS;
const URI_USER = process.env.REACT_APP_URI_USERS;
const URI_SERVER_DATE = process.env.REACT_APP_URI_SERVER_DATE;

export default function NewRequest(props) {
	const [showTab, setShowTab] = useState("general")

	// VARIABLE TO MANAGE THE SUPPLIER
	const [supplier, setSupplier] = useState(null)
	const [serverDate, setServerDate] = useState(null)

	useEffect(() => {
		if (props.show) {
			getSupplierByID()
			getServerDate()
		}
	}, [props.show])

	const getServerDate = () => {
		axios.get(URI_SERVER_DATE).then(response => {
			setServerDate(response.data)
		}).catch(err => {
			console.log("Error al obtener la fecha del servidor" + err)
		})
	}

	//FUNCTION TO GET THE SUPPLIER INFORMATION 
	const getSupplierByID = () => {
		axios.get(URI + props.supplierID).then(response => {
			setSupplier(response.data)
		}).catch(err => {
			console.log("Error al actualizar el supplier" + err)
		})
	}

	const preAuthorizeSupplier = (id) => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})
		const supplierToSave = supplier

		const alreadyClientActive = supplierToSave.clients.some(client => client.status === "Ready for review" || client.status === "Active" || client.status === "Authorized")

		const clientToUpdate = supplierToSave.clients.find(client => client.clientID === props.supplierClientID)

		clientToUpdate.statusHistory.push({
			prevStatus: clientToUpdate.status,
			dateStatusChanged: serverDate.date
		})

		clientToUpdate.status = alreadyClientActive ? "Ready for review" : "Pre-authorized"

		axios.put(URI + id, {
			supplier: JSON.stringify(supplierToSave),
			supplierClientID: JSON.stringify([props.supplierClientID]),
			sendEmail: true,
			dataID: props.userLogged.dataID,
			rol: props.userLogged.rol
		}).then(() => {
			axios.get(URI_USER + supplierToSave._id).then(response => {
				if (!response.data.exist) {
					axios.post(URI_USER, {
						username: supplier.informationCompliance.generalInformation.contactInformation?.email,
						rol: "Supplier",
						dataID: supplier._id,
					}).then(() => {
						props.handleClose();
						Swal.fire({
							icon: 'success',
							text: 'Supplier pre-authorized and User created successfully',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
					}).catch(err => {
						console.log("Error al agregar el usuario" + err)
					})
				} else {
					Swal.fire({
						icon: 'success',
						text: 'Supplier pre-authorized successfully',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					props.handleClose();
				}

				const data = {
					username: props.userLogged?.username,
					supplierID: supplierToSave._id,
					status: "New request",
					clientID: props.supplierClientID
				}
				
				try {
					axios.patch(process.env.REACT_APP_CREATE_ACTION, data)

					if (alreadyClientActive) {
						addAction("Ready for review", supplierToSave._id, props.supplierClientID)
					}
				} catch (error) {
					console.log(error)
				}
			}).catch(err => {
				console.log("Error al obtener el usuario" + err)
			})
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				text: 'Supplier pre-authorized failed',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			console.log("Error al actualizar el supplier" + err)
		})
	}

	const rejectSupplier = (id) => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		const supplierToSave = supplier
		supplierToSave.clients = supplierToSave.clients.filter(client => client.clientID !== props.supplierClientID)

		const data = {
			username: props.userLogged?.username,
			supplierID: supplierToSave._id,
			status: "New request",
			clientID: props.supplierClientID
		}

		if (supplierToSave.clients?.length === 0) {
			axios.delete(URI + id).then(() => {
				Swal.fire({
					icon: 'success',
					text: 'Supplier request rejected successfully',
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})

				deleteAllActionsBySupplierId(id)
	
				props.handleClose();
			}).catch(err => {
				Swal.fire({
					icon: 'error',
					text: "Supplier request rejected failed" + err,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
				})
			})

			return
		}

		axios.put(URI + id, {
			supplier: JSON.stringify(supplierToSave),
		}).then(() => {
			Swal.fire({
				icon: 'success',
				text: 'Supplier request rejected successfully',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})

			axios.patch(process.env.REACT_APP_CREATE_ACTION, data).then().catch(err => {
				console.log(err)
			})

			props.handleClose();
		}).catch(err => {
			console.log(err)
			Swal.fire({
				icon: 'error',
				text: "Supplier request rejected failed" + err,
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
		})
	}

	const clientName = useMemo(() => supplier?.clients?.find(client => client.clientID.toString() === props.supplierClientID)?.clientName, [supplier])

	return (
		<Modal
			className="modalNewRequest"
			show={props.show}
			size="xl"
			centered
			onHide={props.handleClose}
		>
			<Modal.Body>
				<img className="closeModal" src={close} alt="Prodensa Supplier" onClick={props.handleClose} />
				<Tabs activeKey={showTab} onSelect={(e) => setShowTab(e)}>
					<Tab eventKey="general" title="General Information">
						<div className="tabHeaderContainer">
							<div>
								<h4 className="title">General information</h4>
								<p className="description">Company information that the supplier submitted through the landing page.</p>
							</div>

							<div className="clientNameContainer">
								<h4 className="title">{clientName}</h4>
								<p className="description" style={{ opacity: "0.5" }}>Client</p>
							</div>
						</div>
						<form>
							<div className="inputContainer">
								<span className="label">Company country</span>
								<input disabled
									value={supplier && countryInformation.find(countries => {
										return countries.code === supplier.informationCompliance.generalInformation.country
									}).country} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">
									{supplier && countryInformation.find(countries => {
										return countries.code === supplier.informationCompliance.generalInformation.country
									}).typePersonLabel}
								</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.typePerson ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Situacion Fiscal</span>
								<div className="fileContainer">
									{supplier?.informationCompliance.generalInformation.situacionFiscal?.document &&
										<a className="downloadFile btnBgBlueTextGradient"
											href={process.env.REACT_APP_URI_UPLOADS +
												supplier?.informationCompliance.generalInformation.rfc + "/" +
												supplier?.informationCompliance.generalInformation.situacionFiscal?.document}
											target="_blank">View attached file</a>
									}
									<span className="fileName">{supplier?.informationCompliance.generalInformation.situacionFiscal?.document ? supplier?.informationCompliance.generalInformation.situacionFiscal?.document : "No file uploaded"}</span>
								</div>
							</div>
							<div className="inputContainer">
								<span className="label">Presentation/Curriculum</span>
								<div className="fileContainer">
									{supplier?.informationCompliance.generalInformation.curriculum.document &&
										<a className="downloadFile btnBgBlueTextGradient"
											href={process.env.REACT_APP_URI_UPLOADS +
												supplier?.informationCompliance.generalInformation.rfc + "/" +
												supplier?.informationCompliance.generalInformation.curriculum.document}
											target="_blank">View attached file</a>
									}
									<span className="fileName">{supplier?.informationCompliance.generalInformation.curriculum.document ? supplier?.informationCompliance.generalInformation.curriculum.document : "No file uploaded"}</span>
								</div>
							</div>
							<div className="inputContainer">
								<span className="label">Name of company</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.companyName ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">
									{supplier && countryInformation.find(countries => {
										return countries.code === supplier.informationCompliance.generalInformation.country
									}).rfcLabel}
								</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.rfc ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Year founded</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.yearFounded ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Number of employees</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.numberEmployee ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Average annual sales</span>
								<input disabled value={supplier && currencyFormat(supplier.informationCompliance.salesInformation.anualSales[0].sales)} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Website / Social network</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.website ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Official address</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.address.streetAndNumber ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<input disabled value={supplier?.informationCompliance.generalInformation.address.zipCode ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<input disabled value={supplier?.informationCompliance.generalInformation.address.city ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<input disabled value={supplier?.informationCompliance.generalInformation.address.state ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Contact details</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.contactInformation?.name ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<input disabled value={supplier?.informationCompliance.generalInformation.contactInformation?.role ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<input disabled value={supplier?.informationCompliance.generalInformation.contactInformation?.email ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<input disabled value={supplier?.informationCompliance.generalInformation.contactInformation?.phone ?? ""} type="text" />
							</div>
						</form>
					</Tab>

					<Tab eventKey="industry" title="Industry selection">
						<h4 className="title">Industry selection</h4>
						<p className="description">What industry does your company belong to?</p>
						<div className="industryContainer">
							<div className="inputContainer">
								<span className="label">Category of supplier</span>
								<input disabled value={supplier?.informationCompliance.facilitiesCapabilities.categories.map(category => {
									return category + " "
								})} type="text" />
							</div>
							<div className="inputContainer radioRepse">
								<span className="label">¿Cuenta con número REPSE?</span>
								<input id="repseYes" checked={supplier?.informationCompliance.generalInformation.hasRepse ? true : false} disabled className="yes" type="radio" name="repseNumer" />
								<label htmlFor="repseYes">Yes</label>
								<input id="repseNo" checked={supplier?.informationCompliance.generalInformation.hasRepse ? false : true} disabled className="no" type="radio" name="repseNumer" />
								<label htmlFor="repseNo">No</label>
							</div>
							<div className={`inputContainer textRepse ${supplier?.informationCompliance.generalInformation.hasRepse ? "show" : ""}`}>
								<span className="label">Número REPSE</span>
								<input disabled value={supplier?.informationCompliance.generalInformation.numberRepse ?? ""} type="text" />
							</div>
							<div className="inputContainer">
								<span className="label">Description of products/services offered by company</span>
								<textarea disabled value={supplier?.informationCompliance.facilitiesCapabilities.description ?? ""} rows={5} draggable={false} />
							</div>
						</div>
					</Tab>
				</Tabs>
				{(supplier && supplier.clients.find(client => client.clientID === props.supplierClientID)?.status === "New request")
					?
					<>
						<h4 className="titleActions">Review request</h4>
						<div className="buttonsContainer">
							<button disabled={!serverDate} className="btnBgBlueTextGradient" onClick={() => preAuthorizeSupplier(supplier._id)}>Pre-authorize & request documents</button>
							<button className="btnBgBlueTextGradient" onClick={() => rejectSupplier(supplier._id)}>Reject supplier</button>
						</div>
					</>
					: undefined
				}
			</Modal.Body>
		</Modal >
	)
}