import "../../assets/css/navigationMenu/navigationMenu.css"

import moon from "../../assets/images/dark_mode.svg"
import sun from "../../assets/images/wb_sunny.svg"
import logo from "../../assets/images/logo.png"
import iconProdensa from "../../assets/images/iconProdensa.svg"
import complianceSetup from "../../assets/images/complianceSetup.svg"
import myProfile from "../../assets/images/myProfile.svg"
import dashboard from "../../assets/images/dashboard.svg"
import suppliers from "../../assets/images/suppliers.svg"
import industries from "../../assets/images/industries.svg"
import requests from "../../assets/images/requests.svg"
import client from "../../assets/images/clients.svg"
import alert from "../../assets/images/alerts.svg"
import securityStaff from "../../assets/images/securityStaff.svg"
import forward from "../../assets/images/arrowForward.svg"
import menuBlue from "../../assets/images/menuBlue.svg"
import downloadReport from "../../assets/images/downloadReport.svg"

import { useEffect, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useCookies } from "react-cookie"
import { useRef } from "react"
import axios from "axios"
import Swal from "sweetalert2"

const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS
const URI_REPORT_SUPPLIERS = process.env.REACT_APP_REPORT_SUPPLIERS
const URI_REPORT_REFFERALS = process.env.REACT_APP_REPORT_REFFERALS

function MenuButton(props) {
    return (
        props.show
            ? <button className={`btnNav ${props.darkLightMode ? "darkMode" : null} ${props.isActive ? "active" : ""}`} onClick={props.onClick}>
                <img className="iconBtnNav" src={props.image} alt="Prodensa Supplier" />
                {props.title}
                {(props.id !== 8 && props.id !== 9) && <img className="arrowBtnNav" src={forward} alt="Prodensa Supplier" />}
            </button>
            : undefined
    )
}


export default function NavigationMenu(props) {
    // VARIABLE TO KNOW WHOS IS LOGGED
    const [cookie, setCookie, removeCookie] = useCookies(["userLogged"])
    const userLogged = cookie.userLogged

    const url = useLocation();
    const urlPathname = url.pathname;
    
    const MENU_BUTTONS = [
        {
            _id: 0,
            route: "complianceSetup",
            title: "Compliance setup",
            image: complianceSetup,
            isActive: false,
            show: userLogged.rol === "Supplier" && props.firstTime ? true : false,
        },
        {
            _id: 1,
            route: "myProfile",
            title: "My profile",
            image: myProfile,
            isActive: false,
            show: userLogged.rol === "Supplier" && !props.firstTime ? true : false,
        },
        {
            _id: 2,
            route: "dashboard",
            title: "Dashboard",
            image: dashboard,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" || userLogged.rol === "Client" ? true : false,
        },
        {
            _id: 3,
            route: "suppliers",
            title: "Suppliers",
            image: suppliers,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" || userLogged.rol === "Client" ? true : false,
        },
        {
            _id: 4,
            route: "industries",
            title: "Industries",
            image: industries,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" ? true : false,
        },
        {
            _id: 5,
            route: "requests",
            title: "Requests",
            image: requests,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" || userLogged.rol === "Client" ? true : false,
        },
        {
            _id: 6,
            route: "clients",
            title: "Clients",
            image: client,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" ? true : false,
        },
        {
            _id: 7,
            route: "alerts",
            title: "Alerts",
            image: alert,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" || userLogged.rol === "Client" ? true : false,
        },
        {
            _id: 8,
            route: "securityStaff",
            title: "Security Staff",
            image: securityStaff,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client" || userLogged.rol === "Client" ? true : false,
        },
        {
            _id: 9,
            title: "Supplier Report",
            image: downloadReport,
            isActive: false,
            show: userLogged.rol === "Master" || userLogged.rol === "Super admin" ? true : false,
        },
        {
            _id: 10,
            title: "Refferal Report",
            image: downloadReport,
            isActive: false,
            show: userLogged.rol === "Master" ? true : false,
        },
        {
            _id: 11,
            route: "myClients",
            title: "My Clients",
            image: client,
            isActive: false,
            show: userLogged.rol === "Supplier" && !props.firstTime ? true : false,
        },
        {
            _id: 12,
            route: "myAlerts",
            title: "My Alerts",
            image: alert,
            isActive: false,
            show: userLogged.rol === "Supplier" && !props.firstTime ? true : false,
        },
    ]

    const [menuButtons, setMenuButtons] = useState(MENU_BUTTONS);

    // VARIABLE TO GROW THE NAVIGATION MENU
    const [growMenu, setGrowMenu] = useState(false)

    // REFERENCE TO CLOSE TO SHRINK THE NAVITATION MENU
    const menuRef = useRef(null)

    const navigate = useNavigate()

    const mainStyle = {
        background: props.darkLightMode ? "#131E29" : "white",
        color: props.darkLightMode ? "white" : "inherit"
    }

    useEffect(() => {
        if (userLogged.rol === "Supplier") {
            if (props.firstTime) {
                setMenuButtons(menuButtons.map(button => {
                    return {
                        ...button,
                        isActive: button._id === 0 ? true : false
                    }
                }))
                navigate("./complianceSetup")
            } else {
                setMenuButtons(menuButtons.map(button => {
                    const existingRoute = urlPathname.split("/home")[1]?.split("/")[1] || "myProfile"

                    const routeToFind = existingRoute !== "complianceSetup" ? existingRoute : "myProfile"
                    
                    const buttonID = MENU_BUTTONS.find(button => button.route === routeToFind)?._id
                    
                    return {
                        ...button,
                        isActive: button._id === buttonID ? true : false
                    }
                }))

                if (!(urlPathname.split("/home")[1] || null) || urlPathname.split("/home")[1]?.split("/")[1] === "complianceSetup") {
                    navigate("./myProfile")
                }
            }
        } else {
            setMenuButtons(menuButtons.map(button => {
                const existingRoute = urlPathname.split("/home")[1]?.split("/")[1] || "dashboard"

                const buttonID = MENU_BUTTONS.find(button => button.route === existingRoute)?._id
                
                return {
                    ...button,
                    isActive: button._id === buttonID ? true : false
                }
            }))

            if (!(urlPathname.split("/home")[1] || null)) {
                navigate("./dashboard")
            }
        }

        document.body.addEventListener("click", closeMenu)

        return function cleanUp() {
            document.body.removeEventListener("click", closeMenu)
        }
    }, [])

    const closeMenu = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            setGrowMenu(false)
        }
    }

    const handleDarkMode = () => {
        props.setDarkLightMode(prev => {
            axios.patch(process.env.REACT_APP_URI_USERS, { darkLightMode: !prev, dataID: userLogged.dataID }).then().catch(err => console.log(err))

            if (cookie.userLogged) {
                const actualDate = new Date();
                const cookieExpiration = actualDate.setDate(actualDate.getDate() + 7)
                const maxAge = Math.floor((cookieExpiration - new Date()) / 1000)

                const cookieData = cookie.userLogged

                cookieData.darkLightMode = !prev
                
                setCookie("userLogged", JSON.stringify(cookieData), { maxAge })
            }
            
            return !prev
        })
    }

    const handleClickMenuButton = (id) => {
        props.setShowMobileMenu(false)

        setMenuButtons(menuButtons.map(button => {
            return {
                ...button,
                isActive: button._id === id ? true : false
            }
        }))

        localStorage.setItem("requestFilters", JSON.stringify({}))
        localStorage.setItem("suppDirFilters", JSON.stringify({}))
        localStorage.setItem("clientFilters", JSON.stringify({}))

        switch (menuButtons.find(state => state._id === id).title) {
            case "Compliance setup":
                navigate("./complianceSetup")
                break;

            case "My profile":
                navigate("./myProfile")
                break;

            case "Dashboard":
                navigate("./dashboard")
                break;

            case "Suppliers":
                navigate("./suppliers")
                break;

            case "Industries":
                navigate("./industries")
                break;

            case "Requests":
                navigate("./requests")
                break;

            case "Clients":
                navigate("./clients")
                break;

            case "Alerts":
                navigate("./alerts")
                break;

            case "Supplier Report":
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.get(URI_REPORT_SUPPLIERS).then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: `${response.data.message}`,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                    window.open(URI_UPLOADS + response.data.path, "_blank")
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to generate the report',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
                break;

            case "Refferal Report":
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.get(URI_REPORT_REFFERALS).then(response => {
                    Swal.fire({
                        icon: 'success',
                        text: `${response.data.message}`,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                    window.open(URI_UPLOADS + response.data.path, "_blank")
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to generate the report',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                })
                break;

            case "My Clients":
                navigate("./myClients")
                break;

            case "My Alerts":
                navigate("./myAlerts")
                break;

            case "Security Staff":
                navigate("./securityStaff")
                break;

            default:
                break;
        }
    }

    return (
        <nav style={mainStyle} ref={menuRef} className={`navigationMenu ${props.darkLightMode ? "darkMode" : null} ${props.showMobileMenu ? "show" : ""} ${growMenu ? "grow" : undefined}`}>
            <div>
                <img className="hamburguer" src={menuBlue} alt="Prodensa Supplier" onClick={() => props.setShowMobileMenu(false)} />
                <img className="logo" src={logo} alt="Prodensa Supplier" />
                <img className="iconProdensa" src={iconProdensa} alt="Prodensa Supplier" />
            </div>
            {menuButtons.map(button => (
                <MenuButton
                    key={button._id}
                    id={button._id}
                    title={button.title}
                    image={button.image}
                    isActive={button.isActive}
                    show={button.show}
                    onClick={() => handleClickMenuButton(button._id)}
                    darkLightMode={props.darkLightMode}
                />
            ))}
            <button style={mainStyle} className={`btnNav darkModeIcon`} onClick={handleDarkMode}>
                <img className="iconBtnNav" src={props.darkLightMode ? sun : moon} alt="Prodensa Supplier" />
                {props.darkLightMode ? "Light mode" : "Dark mode"}
            </button>
            <div className={`iconGrowMenu ${props.darkLightMode ? "darkMode" : null}`} onClick={() => setGrowMenu(prevState => !prevState)}></div>
            <Link to={"/login"} className={`btnBgBlueTextGradient`} onClick={() => removeCookie("userLogged")}>Sign Out</Link>
        </nav>
    )
}