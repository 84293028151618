import "../../assets/css/clientsAdminView/clientsAdminView.css"

import status from "../../assets/images/status.svg"
import industry from "../../assets/images/industries.svg"
import location from "../../assets/images/location.svg"
import sortIcon from "../../assets/images/sort.svg"
import viewProfile from "../../assets/images/viewProfile.svg"
import next from "../../assets/images/arrowForward.svg"

import close from "../../assets/images/close.svg"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import moment from "moment"

const URI_CLIENT = process.env.REACT_APP_URI_CLIENT

export default function ClientsAdminView({ darkLightMode }) {
	// VARIABLE TO CONTROL DATA FROM TABLE
	const [dataTable, setDataTable] = useState([])
	const [dataTableCopy, setDataTableCopy] = useState([])

	const [currentPage, setCurrentPage] = useState(1)

	let totalPages = Math.ceil(dataTableCopy.length / 8);
	let indexOfLastItem = currentPage * 8;
	let indexOfFirstItem = indexOfLastItem - 8;
	let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

	let paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	// VARAIBLE TO CONTROL THE SORT ORDER
	const [order, setOrder] = useState(true)

	// VARIABLES TO CONTROL SORT MENU SHOW AND HIDE
	const [showSortMenu, setShowSortMenu] = useState(false)

	// VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
	const sortMenuRef = useRef(null)
	const filterRef = useRef(null)

	// VARIABLE TO CONTROL THE LIST OF LOCATIONS
	const [locationList, setLocationList] = useState([])

	// VARIABLES TO FILTER DATA
	const [statusFilter, setStatusFilter] = useState(() => {
        const stored = localStorage.getItem("clientFilters") ?? JSON.stringify({})
        
        return JSON.parse(stored)?.status ?? []
    })
	const [industryFilter, setIndustryFilter] = useState(() => {
        const stored = localStorage.getItem("clientFilters") ?? JSON.stringify({})
        
        return JSON.parse(stored)?.industry ?? []
    })
	const [locationFilter, setLocationFilter] = useState(() => {
        const stored = localStorage.getItem("clientFilters") ?? JSON.stringify({})
        
        return JSON.parse(stored)?.location ?? []
    })

	// VARIABLES TO KNOW IF THE FILTER IS ON
	const [statusIsFiltered, setStatusIsFiltered] = useState(() => {
        const stored = localStorage.getItem("clientFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.status ? true : false
    })
	const [industryIsFiltered, setIndustryIsFiltered] = useState(() => {
        const stored = localStorage.getItem("clientFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.industry ? true : false
    })
	const [locationIsFiltered, setLocationIsFiltered] = useState(() => {
        const stored = localStorage.getItem("clientFilters") ?? JSON.stringify({})

        return JSON.parse(stored)?.location ? true : false
    })

	const [industrySearch, setIndustrySearch] = useState("")
    const [locationSearch, setLocationSearch] = useState("")

    const [openFilter, setOpenFilter] = useState(null)

	//VARIABLE TO CONTROL THE FILTER DATA AFTER CLEAR A SINGLE FILTER
	const [hardFilterData, setHardFilterReset] = useState(false)

	//VARIABLE TO SHOW RESET ALL FILTERS
	const [resetAll, setResetAll] = useState(false)

	const [searchValue, setSearchValue] = useState(() => {
        const stored = localStorage.getItem("clientFilters")

        return JSON.parse(stored)?.searchValue ?? ""
    })

	// VARIABLE TO NAVIGATE
	const navigate = useNavigate();

	const mainStyle = {
		background: darkLightMode ? "#131E29" : "white",
		color: darkLightMode ? "white" : "inherit"
	}

	// useEffect to add body listener
	useEffect(() => {
		getAllClients()

		document.body.addEventListener("mousedown", closeMenusFilters)

		return function cleanUp() {
			document.body.removeEventListener("mousedown", closeMenusFilters)
		}
	}, [])

	useEffect(() => {
        const savedFilters = Object.values(JSON.parse(localStorage.getItem("clientFilters")) ?? {}).filter(item => !(typeof item === "string")).length
		const savedSearchValue = JSON.parse(localStorage.getItem("clientFilters") ?? {})?.searchValue ?? ""

        if (dataTable.length > 0 && savedFilters > 0) {
            filterData()
        }

		if (dataTable && savedSearchValue) {
            searchFilter(savedSearchValue)
        }
    }, [dataTable])

	// COMPARATION TO SHOW THE RESET ALL BUTTON
	useEffect(() => {
		if (industryIsFiltered || statusIsFiltered || locationIsFiltered) {
			setResetAll(true)
		} else {
			setResetAll(false)
		}
	}, [industryIsFiltered, statusIsFiltered, locationIsFiltered])

	// ACTION TO EXECUTE WHEN YOU CLEAR A SINGLE FILTER TO RESET THE TABLE
	useEffect(() => {
		if (hardFilterData) {
			filterData()
			setHardFilterReset(false)
		}
	}, [hardFilterData])

	// FUNCTION TO CLOSE MENU FILTERS
	const closeMenusFilters = (e) => {
		if (!filterRef?.current?.contains(e.target)) {
            setOpenFilter(null)
        }

		if (!sortMenuRef?.current?.contains(e.target)) {
			setShowSortMenu(false)
		}
	}

	const handleOpenFilter = (e, filterName) => {
        filterRef.current = e.target

        setOpenFilter(prev => {
            if (prev === filterName) return null

            return filterName
        })
    }

	// FUNCTION TO SORT TABLE COLUMN
	const sortTable = (order, sortBy) => {
		// ORDER FALSE = ASC; ORDER TRUE = DSC 
		switch (sortBy) {
			case "Name":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.generalInformation.clientName.toLocaleLowerCase();
					let fb = b.generalInformation.clientName.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))

				setOrder(!order)
				break

			case "Industry":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.generalInformation.clientIndustries[0].toLocaleLowerCase();
					let fb = b.generalInformation.clientIndustries[0].toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Location":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let fa = a.billingInformation.clientState.toLocaleLowerCase();
					let fb = b.billingInformation.clientState.toLocaleLowerCase();

					if (!order) {
						if (fa < fb) {
							return -1
						}

						if (fa > fb) {
							return 1
						}
					} else {
						if (fa > fb) {
							return -1
						}

						if (fa < fb) {
							return 1
						}
					}

					return 0
				}))
				setOrder(!order)
				break

			case "Status":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					if (!order) {
						return b.generalInformation.clientActive - a.generalInformation.clientActive
					} else {
						return a.generalInformation.clientActive - b.generalInformation.clientActive
					}
				}))

				setOrder(!order)
				break

			case "Date":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					let da = new Date(a.createdAt);
					let db = new Date(b.createdAt);
					if (!order) {
						return da - db
					} else {
						return db - da
					}
				}))
				setOrder(!order)
				break

			case "Entities":
				setDataTableCopy(dataTableCopy.sort((a, b) => {
					if (!order) {
						return a.entities.length - b.entities.length
					} else {
						return b.entities.length - a.entities.length
					}
				}))
				setOrder(!order)
				break

			default:
				break;
		}
	}

	// FUNCTIONS TO ADD FILTERS
	const toggleFilterValue = (setFilter) => (target) => {
        setFilter(prev => {
            const existValue = prev.some(valueToFind => valueToFind === target.value);
    
            if (existValue) {
                return prev.filter(valueToFind => valueToFind !== target.value);
            }
    
            return [...prev, target.value];
        });
    };

	// FUNCTION TO GET ALL CLIENTS
	const getAllClients = () => {
		axios.get(URI_CLIENT).then(response => {
			setDataTable(response.data)
			setDataTableCopy(response.data)
			createLocationFilter(response.data)
		}).catch(err => {
			console.log("Error al obtener los clientes")
		})
	}

	// FUNCTION TO FILTER THE CLIENTS BY INPUT SEARCH
	const searchFilter = (value) => {
		setSearchValue(value)

		const searchValueToLocal = {
            searchValue: value
        }

        localStorage.setItem("clientFilters", JSON.stringify(searchValueToLocal))
		
		setCurrentPage(1)
		let dataFiltered = []
		dataFiltered = dataTable.filter(prevState => {
			if (prevState.generalInformation.clientName.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
				return prevState
			}
		})

		setDataTableCopy(dataFiltered)
	}

	// FUNCTION TO FILTER THE CLIENTS BY FILTER BUTTONS
	const filterData = () => {
		const saveFilters = {}

		let dataFiltered = JSON.parse(JSON.stringify(dataTable));

		if (statusFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return statusFilter.includes(prevState.generalInformation.clientActive ? "Active" : "Disable")
			})

			setStatusIsFiltered(true)
			saveFilters.status = statusFilter
		}

		if (industryFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return prevState.generalInformation.clientIndustries.some(industry => {
					return industryFilter.includes(industry)
				})
			})

			setIndustryIsFiltered(true)
			saveFilters.industry = industryFilter
		}

		if (locationFilter.length > 0) {
			dataFiltered = dataFiltered.filter(prevState => {
				return locationFilter.includes(prevState.billingInformation.clientState)
			})

			setLocationIsFiltered(true)
			saveFilters.location = locationFilter
		}

		setSearchValue("")
		setDataTableCopy(dataFiltered)
		localStorage.setItem("clientFilters", JSON.stringify(saveFilters))
	}

	// FUNCTION TO CLEAR THE CLIENTS BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
	const clearFilter = (filter) => {
		setSearchValue("")
		switch (filter) {
			case "status":
				setStatusFilter([])
				setStatusIsFiltered(false)
				break;

			case "industry":
				setIndustryFilter([])
				setIndustryIsFiltered(false)
				break;

			case "location":
				setLocationFilter([])
				setLocationIsFiltered(false)
				break;

			default:
				clearFilter("status")
				clearFilter("industry")
				clearFilter("location")
				break;
		}

		setHardFilterReset(true)
	}

	// FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
	const createIndustryFilter = () => {
		const filterIndustry = new Set(dataTable.flatMap(client => {
			return client.generalInformation.clientIndustries.map(industry => industry)
		}))

		return Array.from(filterIndustry).sort().filter(item => !industrySearch || item?.toLocaleLowerCase()?.includes(industrySearch.toLocaleLowerCase()))
	}

	// FUNCTION TO CREATE THE LOCATION LIST TO SHOW ON FILTER BUTTON
	const createLocationFilter = (data) => {
		const filterLocation = new Set(data.map(client => {
			return client.billingInformation.clientState
		}))

		setLocationList(Array.from(filterLocation).sort())
	}

	return (
		<section className={`clientsAdminView ${darkLightMode ? "darkMode" : null}`}>
			<div className="titleAndSearch">
				<h3 className="title">Clients</h3>
				<div className="searchAndSort">
					<div className={`searchContainer ${darkLightMode ? "darkMode" : null}`}>
						<input id="generalSearch" type="text" placeholder="Search by name..."
							onFocus={() => clearFilter()} value={searchValue} onChange={(e) => searchFilter(e.target.value)} />
					</div>
					<button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
					<div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
						<h5 className="title">Sort</h5>
						<span onClick={() => { sortTable(false, "Name") }}>Name (ascending)</span>
						<span onClick={() => { sortTable(true, "Name") }}>Name (descending)</span>
						<span onClick={() => { sortTable(false, "Industry") }}>Industry (ascending)</span>
						<span onClick={() => { sortTable(true, "Industry") }}>Industry (descending)</span>
						<span onClick={() => { sortTable(false, "Location") }}>Location (ascending)</span>
						<span onClick={() => { sortTable(true, "Location") }}>Location (descending)</span>
						<span onClick={() => { sortTable(false, "Status") }}>Status (ascending)</span>
						<span onClick={() => { sortTable(true, "Status") }}>Status (descending)</span>
						<span onClick={() => { sortTable(false, "Date") }}>Registration (ascending)</span>
						<span onClick={() => { sortTable(true, "Date") }}>Registration (descending)</span>
						<span onClick={() => { sortTable(false, "Entities") }}>Entities (ascending)</span>
						<span onClick={() => { sortTable(true, "Entities") }}>Entities (descending)</span>
					</div>
				</div>
			</div>
			<div className={`filters ${darkLightMode ? "darkMode" : null}`}>
				<div className={`filter ${openFilter === "status" ? "show" : null} ${statusIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "status")}>
					<img src={status} alt="Prodensa Supplier" />
					<span>Status</span>
					<div className={`filterOptions ${openFilter === "status" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<img src={close} alt="Prodensa Automation" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
						<span className="title">
							<img className="d-lg-none imgMenu" src={status} alt="Prodensa Supplier" />
							Status
						</span>
						<div className="optionsScroll">
							<div className="option">
								<input checked={statusFilter.includes("Active")} className="statusCheck" value="Active" type="checkbox" id={`statusActive`} onChange={(e) => toggleFilterValue(setStatusFilter)(e.target)} />
								<label htmlFor={`statusActive`}>Active</label>
							</div>
							<div className="option">
								<input checked={statusFilter.includes("Disable")} className="statusCheck" value="Disable" type="checkbox" id={`statusDisable`} onChange={(e) => toggleFilterValue(setStatusFilter)(e.target)} />
								<label htmlFor={`statusDisable`}>Disable</label>
							</div>
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("status")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<div className={`filter ${openFilter === "industry" ? "show" : null} ${industryIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "industry")}>
					<img src={industry} alt="Prodensa Supplier" />
					<span>Industry</span>
					<div className={`searchContainer ${openFilter === "industry" ? "show" : null}`} onClick={(e) => e.stopPropagation()}>
						<input value={industrySearch} type="text" placeholder="Search industries"
							onChange={(e) => setIndustrySearch(e.target.value)} />
					</div>
					<div className={`filterOptions location ${openFilter === "industry" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<img src={close} alt="Prodensa Automation" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
						<span className="title">
							<img className="d-lg-none imgMenu" src={industry} alt="Prodensa Supplier" />
							Industry
						</span>
						<div className="optionsScroll">
							{createIndustryFilter().map((industry, index) => {
								return (
									<div key={index} className="option">
										<input checked={industryFilter.includes(industry)} className="industryCheck" value={industry} type="checkbox" id={`industry${index}`} onChange={(e) => toggleFilterValue(setIndustryFilter)(e.target)} />
										<label htmlFor={`industry${index}`} >{industry}</label>
									</div>
								)
							})}
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("industry")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<div className={`filter location ${openFilter === "location" ? "show" : null} ${locationIsFiltered ? "active" : undefined}`} onClick={(e) => handleOpenFilter(e, "location")}>
					<img src={location} alt="Prodensa Supplier" />
					<span>Location</span>
					<div className={`searchContainer ${openFilter === "location" ? "show" : null}`} onClick={(e) => e.stopPropagation()}>
						<input value={locationSearch} type="text" placeholder="Search cities"
							onChange={(e) => setLocationSearch(e.target.value)} />
					</div>
					<div className={`filterOptions location ${openFilter === "location" ? "show" : null}`} onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
						<img src={close} alt="Prodensa Automation" onClick={() => setOpenFilter(null)} className="closeImg d-lg-none" />
						<span className="title">
							<img className="d-lg-none imgMenu" src={location} alt="Prodensa Supplier" />
							Location
						</span>
						<div className="optionsScroll">
							{locationList.filter(item => !locationSearch || item?.toLocaleLowerCase()?.includes(locationSearch.toLocaleLowerCase()))
							.map((filter, index) => (
								<div key={index} className="option">
									<input checked={locationFilter.includes(filter)} className="locationCheck" value={filter} type="checkbox" id={`location${index}`} onChange={(e) => toggleFilterValue(setLocationFilter)(e.target)} />
									<label htmlFor={`location${index}`} >{filter}</label>
								</div>
							))}
						</div>
						<div className="actions">
							<button onClick={() => clearFilter("location")}>Clear</button>
							<button onClick={() => filterData()}>Apply</button>
						</div>
					</div>
				</div>
				<button className="btnBgBlueTextGradient" onClick={() => navigate("./registerClient/")}>New Client</button>
			</div>
			<span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>

			<table className="tableClientsAdminView">
				<thead>
					<tr>
						<th style={mainStyle}>
							<span>Name</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
						</th>
						<th style={mainStyle}>
							<span>Industry</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
						</th>
						<th style={mainStyle}>
							<span>Location</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Location")} />
						</th>
						<th style={mainStyle}>
							<span>Status</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
						</th>
						<th style={mainStyle}>
							<span>Registration</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Date")} />
						</th>
						<th style={mainStyle}>
							<span>Entities</span>
							<img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Entities")} />
						</th>
					</tr>
				</thead>
				<tbody>
					{currentItems.map((data, index) => (
						<tr key={index}>
							<td>
								<div className="infoContainer">
									<span onClick={() => navigate("./clientProfile/" + data._id)}>
										{data.generalInformation.clientName}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{data.generalInformation.clientIndustries.map((industry, index) => {
										return data.generalInformation.clientIndustries.length > index + 1 ? industry + ", " : industry
									})}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{data.billingInformation.clientState}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span className={`${data.generalInformation.clientActive ? "success" : "failedAlone"}`}>
										{data.generalInformation.clientActive ? "Active" : "Disabled"}
									</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{moment(data.createdAt).format("DD MMM YYYY")}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{data.entities.length}</span>
									<img className="viewProfile" src={viewProfile} alt="Prodensa Supplier" onClick={() => navigate("./clientProfile/" + data._id)} />
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="d-lg-none clientList">
				{currentItems.map((data, index) => (
					<div key={index} className="clientRow" onClick={() => navigate("./clientProfile/" + data._id)}>
						<h5 className="clientName">{data.generalInformation.clientName}</h5>
						<div className="clientInformation">
							<span className="clientValue">{data.billingInformation.clientState}</span>
							<span className="clientTitle">Location</span>
							<span className={`clientValue ${data.generalInformation.clientActive ? "success" : "failedAlone"}`}>
								{data.generalInformation.clientActive ? "Active" : "Disabled"}
							</span>
							<span className="clientTitle">Status</span>
							<span className="clientValue">{data.generalInformation.clientIndustries.map((industry, index) => {
								return data.generalInformation.clientIndustries.length > index + 1 ? industry + ", " : industry
							})}</span>
							<span className="clientTitle">Industry</span>
							<span className="clientValue">{moment(data.createdAt).format("DD MMM YYYY")}</span>
							<span className="clientTitle">Registration</span>
							<span className="clientValue">{data.entities.length}</span>
							<span className="clientTitle">Entities</span>
						</div>
					</div>
				))}
			</div>

			<div className="addAndPagination">
				<div className="pagination">
					{Array.from({ length: totalPages }).map((_, index) => (
						<button key={index} className={`number ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
							{index + 1}
						</button>
					))}
					<img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => currentPage < totalPages ? setCurrentPage(currentPage + 1) : ""} />
				</div>
			</div>
			<span className="tableCount">{currentItems.length < 7 ? currentItems.length : 8 * currentPage} of {dataTable.length}</span>

			<button className="btnBgBlueTextGradient d-lg-none" onClick={() => navigate("./registerClient/")}>New Client</button>
		</section>
	)
}